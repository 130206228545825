/* eslint-disable */
import * as Types from '../types.generated';

import {
    GlobalTagsFieldsFragment,
    StructuredPropertiesFieldsFragment,
    InstitutionalMemoryFieldsFragment,
    OwnershipFieldsFragment,
    GlossaryTermsFragment,
    EntityDomainFragment,
    PlatformFieldsFragment,
    EntityDataProduct_AccessTokenMetadata_Fragment,
    EntityDataProduct_Assertion_Fragment,
    EntityDataProduct_Chart_Fragment,
    EntityDataProduct_Container_Fragment,
    EntityDataProduct_CorpGroup_Fragment,
    EntityDataProduct_CorpUser_Fragment,
    EntityDataProduct_Dashboard_Fragment,
    EntityDataProduct_DataFlow_Fragment,
    EntityDataProduct_DataHubPolicy_Fragment,
    EntityDataProduct_DataHubRole_Fragment,
    EntityDataProduct_DataHubView_Fragment,
    EntityDataProduct_DataJob_Fragment,
    EntityDataProduct_DataPlatform_Fragment,
    EntityDataProduct_DataPlatformInstance_Fragment,
    EntityDataProduct_DataProcessInstance_Fragment,
    EntityDataProduct_DataProduct_Fragment,
    EntityDataProduct_DataTypeEntity_Fragment,
    EntityDataProduct_Dataset_Fragment,
    EntityDataProduct_Domain_Fragment,
    EntityDataProduct_EntityTypeEntity_Fragment,
    EntityDataProduct_GlossaryNode_Fragment,
    EntityDataProduct_GlossaryTerm_Fragment,
    EntityDataProduct_MlFeature_Fragment,
    EntityDataProduct_MlFeatureTable_Fragment,
    EntityDataProduct_MlModel_Fragment,
    EntityDataProduct_MlModelGroup_Fragment,
    EntityDataProduct_MlPrimaryKey_Fragment,
    EntityDataProduct_Notebook_Fragment,
    EntityDataProduct_OwnershipTypeEntity_Fragment,
    EntityDataProduct_Post_Fragment,
    EntityDataProduct_QueryEntity_Fragment,
    EntityDataProduct_Role_Fragment,
    EntityDataProduct_SchemaFieldEntity_Fragment,
    EntityDataProduct_StructuredPropertyEntity_Fragment,
    EntityDataProduct_Tag_Fragment,
    EntityDataProduct_Test_Fragment,
    EntityDataProduct_VersionedDataset_Fragment,
    NonRecursiveDataFlowFieldsFragment,
    DeprecationFieldsFragment,
    EmbedFieldsFragment,
    DataPlatformInstanceFieldsFragment,
    ParentContainersFieldsFragment,
    BrowsePathV2FieldsFragment,
    InputFieldsFieldsFragment,
    EntityContainerFragment,
    TypeOfRelationsFieldsFragment,
    ParentNodesFieldsFragment,
    GlossaryNodeFragment,
    GlossaryTermFragment,
    ParentDomainsFieldsFragment,
    DomainEntitiesFieldsFragment,
    NonRecursiveMlFeatureTableFragment,
    NonRecursiveMlFeatureFragment,
    NonRecursiveMlPrimaryKeyFragment,
    SchemaMetadataFieldsFragment,
    NonConflictingPlatformFieldsFragment,
    EntityDisplayNameFields_AccessTokenMetadata_Fragment,
    EntityDisplayNameFields_Assertion_Fragment,
    EntityDisplayNameFields_Chart_Fragment,
    EntityDisplayNameFields_Container_Fragment,
    EntityDisplayNameFields_CorpGroup_Fragment,
    EntityDisplayNameFields_CorpUser_Fragment,
    EntityDisplayNameFields_Dashboard_Fragment,
    EntityDisplayNameFields_DataFlow_Fragment,
    EntityDisplayNameFields_DataHubPolicy_Fragment,
    EntityDisplayNameFields_DataHubRole_Fragment,
    EntityDisplayNameFields_DataHubView_Fragment,
    EntityDisplayNameFields_DataJob_Fragment,
    EntityDisplayNameFields_DataPlatform_Fragment,
    EntityDisplayNameFields_DataPlatformInstance_Fragment,
    EntityDisplayNameFields_DataProcessInstance_Fragment,
    EntityDisplayNameFields_DataProduct_Fragment,
    EntityDisplayNameFields_DataTypeEntity_Fragment,
    EntityDisplayNameFields_Dataset_Fragment,
    EntityDisplayNameFields_Domain_Fragment,
    EntityDisplayNameFields_EntityTypeEntity_Fragment,
    EntityDisplayNameFields_GlossaryNode_Fragment,
    EntityDisplayNameFields_GlossaryTerm_Fragment,
    EntityDisplayNameFields_MlFeature_Fragment,
    EntityDisplayNameFields_MlFeatureTable_Fragment,
    EntityDisplayNameFields_MlModel_Fragment,
    EntityDisplayNameFields_MlModelGroup_Fragment,
    EntityDisplayNameFields_MlPrimaryKey_Fragment,
    EntityDisplayNameFields_Notebook_Fragment,
    EntityDisplayNameFields_OwnershipTypeEntity_Fragment,
    EntityDisplayNameFields_Post_Fragment,
    EntityDisplayNameFields_QueryEntity_Fragment,
    EntityDisplayNameFields_Role_Fragment,
    EntityDisplayNameFields_SchemaFieldEntity_Fragment,
    EntityDisplayNameFields_StructuredPropertyEntity_Fragment,
    EntityDisplayNameFields_Tag_Fragment,
    EntityDisplayNameFields_Test_Fragment,
    EntityDisplayNameFields_VersionedDataset_Fragment,
} from './fragments.generated';
import { gql } from '@apollo/client';
import {
    GlobalTagsFieldsFragmentDoc,
    StructuredPropertiesFieldsFragmentDoc,
    InstitutionalMemoryFieldsFragmentDoc,
    OwnershipFieldsFragmentDoc,
    GlossaryTermsFragmentDoc,
    EntityDomainFragmentDoc,
    PlatformFieldsFragmentDoc,
    EntityDataProductFragmentDoc,
    NonRecursiveDataFlowFieldsFragmentDoc,
    DeprecationFieldsFragmentDoc,
    EmbedFieldsFragmentDoc,
    DataPlatformInstanceFieldsFragmentDoc,
    ParentContainersFieldsFragmentDoc,
    BrowsePathV2FieldsFragmentDoc,
    InputFieldsFieldsFragmentDoc,
    EntityContainerFragmentDoc,
    TypeOfRelationsFieldsFragmentDoc,
    ParentNodesFieldsFragmentDoc,
    GlossaryNodeFragmentDoc,
    GlossaryTermFragmentDoc,
    ParentDomainsFieldsFragmentDoc,
    DomainEntitiesFieldsFragmentDoc,
    NonRecursiveMlFeatureTableFragmentDoc,
    NonRecursiveMlFeatureFragmentDoc,
    NonRecursiveMlPrimaryKeyFragmentDoc,
    SchemaMetadataFieldsFragmentDoc,
    NonConflictingPlatformFieldsFragmentDoc,
    EntityDisplayNameFieldsFragmentDoc,
} from './fragments.generated';
import * as Apollo from '@apollo/client';
export type GetVersionedDatasetQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
    versionStamp?: Types.Maybe<Types.Scalars['String']>;
}>;

export type GetVersionedDatasetQuery = { __typename?: 'Query' } & {
    versionedDataset?: Types.Maybe<
        { __typename?: 'VersionedDataset' } & {
            schema?: Types.Maybe<
                { __typename?: 'Schema' } & Pick<Types.Schema, 'lastObserved'> & {
                        fields: Array<
                            { __typename?: 'SchemaField' } & Pick<
                                Types.SchemaField,
                                | 'fieldPath'
                                | 'jsonPath'
                                | 'nullable'
                                | 'description'
                                | 'type'
                                | 'nativeDataType'
                                | 'recursive'
                                | 'isPartOfKey'
                                | 'isPartitioningKey'
                            >
                        >;
                    }
            >;
            editableSchemaMetadata?: Types.Maybe<
                { __typename?: 'EditableSchemaMetadata' } & {
                    editableSchemaFieldInfo: Array<
                        { __typename?: 'EditableSchemaFieldInfo' } & Pick<
                            Types.EditableSchemaFieldInfo,
                            'fieldPath' | 'description'
                        > & {
                                globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
                                glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
                                domain?: Types.Maybe<{ __typename?: 'DomainAssociation' } & EntityDomainFragment>;
                            }
                    >;
                }
            >;
        }
    >;
};

export const GetVersionedDatasetDocument = gql`
    query getVersionedDataset($urn: String!, $versionStamp: String) {
        versionedDataset(urn: $urn, versionStamp: $versionStamp) {
            schema {
                fields {
                    fieldPath
                    jsonPath
                    nullable
                    description
                    type
                    nativeDataType
                    recursive
                    isPartOfKey
                    isPartitioningKey
                }
                lastObserved
            }
            editableSchemaMetadata {
                editableSchemaFieldInfo {
                    fieldPath
                    description
                    globalTags {
                        ...globalTagsFields
                    }
                    glossaryTerms {
                        ...glossaryTerms
                    }
                    domain {
                        ...entityDomain
                    }
                }
            }
        }
    }
    ${GlobalTagsFieldsFragmentDoc}
    ${GlossaryTermsFragmentDoc}
    ${EntityDomainFragmentDoc}
`;

/**
 * __useGetVersionedDatasetQuery__
 *
 * To run a query within a React component, call `useGetVersionedDatasetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVersionedDatasetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVersionedDatasetQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *      versionStamp: // value for 'versionStamp'
 *   },
 * });
 */
export function useGetVersionedDatasetQuery(
    baseOptions: Apollo.QueryHookOptions<GetVersionedDatasetQuery, GetVersionedDatasetQueryVariables>,
) {
    return Apollo.useQuery<GetVersionedDatasetQuery, GetVersionedDatasetQueryVariables>(
        GetVersionedDatasetDocument,
        baseOptions,
    );
}
export function useGetVersionedDatasetLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetVersionedDatasetQuery, GetVersionedDatasetQueryVariables>,
) {
    return Apollo.useLazyQuery<GetVersionedDatasetQuery, GetVersionedDatasetQueryVariables>(
        GetVersionedDatasetDocument,
        baseOptions,
    );
}
export type GetVersionedDatasetQueryHookResult = ReturnType<typeof useGetVersionedDatasetQuery>;
export type GetVersionedDatasetLazyQueryHookResult = ReturnType<typeof useGetVersionedDatasetLazyQuery>;
export type GetVersionedDatasetQueryResult = Apollo.QueryResult<
    GetVersionedDatasetQuery,
    GetVersionedDatasetQueryVariables
>;
