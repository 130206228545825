import React from 'react';
import styled from 'styled-components';
import { Typography, Image } from 'antd';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Container, Entity, EntityType } from '../../../../../../../types.generated';
import { ANTD_GRAY } from '../../../../constants';
import ContainerLink from './ContainerLink';
import {
    StyledRightOutlined,
    ParentNodesWrapper as ParentContainersWrapper,
    Ellipsis,
    StyledTooltip,
} from './ParentNodesView';
import ParentEntities from '../../../../../../search/filters/ParentEntities';

const LogoIcon = styled.span`
    display: flex;
    gap: 4px;
    margin-right: 8px;
`;

const PreviewImage = styled(Image)`
    max-height: 17px;
    width: auto;
    object-fit: contain;
    background-color: transparent;
`;

const PlatformContentWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 0 8px 6px 0;
    flex-wrap: wrap;
    flex: 1;

    @media (max-width: 768px) {
        margin-right: 0;
    }
`;

const PlatformText = styled(Typography.Text)`
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    color: ${ANTD_GRAY[7]};
    white-space: nowrap;
`;

const PlatformDivider = styled.div`
    display: inline-block;
    padding-left: 8px;
    margin-right: 8px;
    border-right: 1px solid ${ANTD_GRAY[4]};
    height: 18px;
    vertical-align: text-top;
`;

export function getParentContainerNames(containers?: Maybe<Container>[] | null) {
    let parentNames = '';
    if (containers) {
        [...containers].reverse().forEach((container, index) => {
            if (container?.properties) {
                if (index !== 0) {
                    parentNames += ' > ';
                }
                parentNames += container.properties.name;
            }
        });
    }
    return parentNames;
}

interface Props {
    platformName?: string;
    platformLogoUrl?: Maybe<string>;
    platformNames?: Maybe<string>[];
    platformLogoUrls?: Maybe<string>[];
    entityLogoComponent?: JSX.Element;
    instanceId?: string;
    typeIcon?: JSX.Element;
    entityType?: string;
    parentContainers?: Maybe<Container>[] | null;
    parentEntities?: Entity[] | null;
    parentContainersRef: React.RefObject<HTMLDivElement>;
    areContainersTruncated: boolean;
    type?: string;
}

function PlatformContentView(props: Props) {
    const {
        parentEntities,
        platformName,
        platformLogoUrl,
        platformNames,
        platformLogoUrls,
        entityLogoComponent,
        instanceId,
        typeIcon,
        entityType,
        parentContainers,
        parentContainersRef,
        areContainersTruncated,
        type = '',
    } = props;

    const directParentContainer = parentContainers && parentContainers[0];
    const remainingParentContainers = parentContainers && parentContainers.slice(1, parentContainers.length);
    const isDatahub = platformName === 'DataHub';

    // Mapping entity types to their corresponding names
    const entityTypeMap = {
        [EntityType.GlossaryTerm]: 'Glossary Term',
        [EntityType.GlossaryNode]: 'Term Group',
    };

    const entityTypeName = entityTypeMap[type] || entityType;

    return (
        <PlatformContentWrapper>
            {typeIcon && <LogoIcon>{typeIcon}</LogoIcon>}
            <PlatformText>{entityTypeName}</PlatformText>
            {(!!platformName || !!instanceId || !!parentContainers?.length || !!parentEntities?.length) && (
                <PlatformDivider />
            )}
            {platformName && !isDatahub && (
                <LogoIcon>
                    {!platformLogoUrl && !platformLogoUrls && entityLogoComponent}
                    {!!platformLogoUrl && !platformLogoUrls && (
                        <PreviewImage preview={false} src={platformLogoUrl} alt={platformName} />
                    )}
                    {!!platformLogoUrls &&
                        platformLogoUrls.slice(0, 2).map((platformLogoUrlsEntry) => (
                            <>
                                <PreviewImage preview={false} src={platformLogoUrlsEntry || ''} alt={platformName} />
                            </>
                        ))}
                </LogoIcon>
            )}
            {!isDatahub && (
                <PlatformText>
                    {platformNames ? platformNames.join(' & ') : platformName}
                    {(directParentContainer || instanceId) && <StyledRightOutlined data-testid="right-arrow" />}
                </PlatformText>
            )}
            {instanceId && (
                <PlatformText>
                    {instanceId}
                    {directParentContainer && <StyledRightOutlined data-testid="right-arrow" />}
                </PlatformText>
            )}
            <StyledTooltip
                title={getParentContainerNames(parentContainers)}
                overlayStyle={areContainersTruncated ? {} : { display: 'none' }}
            >
                {areContainersTruncated && <Ellipsis>...</Ellipsis>}
                <ParentContainersWrapper ref={parentContainersRef}>
                    {remainingParentContainers &&
                        remainingParentContainers.map((container, index) => (
                            <span key={container?.urn}>
                                <ContainerLink container={container} />
                                {index > 0 && <StyledRightOutlined data-testid="right-arrow" />}
                            </span>
                        ))}
                </ParentContainersWrapper>
                {/* TODO: Hiding the ContainerLink for now */}
                {/* {directParentContainer && <ContainerLink container={directParentContainer} />} */}
            </StyledTooltip>
            <ParentEntities parentEntities={parentEntities || []} numVisible={3} />
        </PlatformContentWrapper>
    );
}

export default PlatformContentView;
