import { useState } from 'react';

function useDropdownKeyboard() {
    const [visible, setVisible] = useState(false);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            setVisible((prev) => !prev); // Toggle dropdown
        } else if (e.key === 'Escape') {
            e.preventDefault();
            setVisible(false); // Close dropdown
        }
    };

    const toggleDropdown = () => {
        setVisible((prev) => !prev);
    };

    return {
        visible,
        setVisible,
        handleKeyDown,
        toggleDropdown,
    };
}

export default useDropdownKeyboard;
