import { FieldType, RecipeField, setListValuesOnRecipe } from './common';

export const XML_TYPE_PLATFORM = 'xml-files.platform';
export const XML_TYPE_PLATFORM_INSTANCE = 'xml-files.platform_instance';
export const XML_TYPE_ENV = 'xml-files.env';

export const XML_PLATFORM: RecipeField = {
    name: XML_TYPE_PLATFORM,
    label: 'Select Platform',
    tooltip: '',
    type: FieldType.SELECT,
    fieldPath: 'source.config.platform',
    placeholder: 'Select Platform',
    rules: [{ required: true, message: 'Platform is required' }],
    required: true,
    options: [
        { label: 'NFS', value: 'nfs' },
        { label: 'Amazon S3', value: 's3' },
        { label: 'SMB', value: 'smb' },
    ],
};

export const XML_PLATFORM_INSTANCE: RecipeField = {
    name: XML_TYPE_PLATFORM_INSTANCE,
    label: 'Select Platform Instance',
    tooltip: '',
    type: FieldType.SELECT,
    fieldPath: 'source.config.platform_instance',
    placeholder: 'Select Platform Instance',
    rules: [{ required: true, message: 'Platform Instance is required' }],
    disabled: true,
    required: true,
    options: [],
    setOptions(xmlData) {
        if (xmlData) {
            this.options = xmlData?.map((field) => ({
                value: String(field.id),
                label: field.name,
            }));
        }
    },
};

export const XML_ENV: RecipeField = {
    name: XML_TYPE_ENV,
    label: 'Select Environment',
    tooltip: '',
    type: FieldType.SELECT,
    fieldPath: 'source.config.env',
    placeholder: 'Select Environment',
    rules: [{ required: true, message: 'Environment is required' }],
    required: true,
    options: [
        {
            label: 'PROD',
            value: 'PROD',
        },
        {
            label: 'DEV',
            value: 'DEV',
        },
        {
            label: 'TEST',
            value: 'TEST',
        },
        {
            label: 'QA',
            value: 'QA',
        },
        {
            label: 'UAT',
            value: 'UAT',
        },
        {
            label: 'EI',
            value: 'EI',
        },
        {
            label: 'PRE',
            value: 'PRE',
        },
        {
            label: 'STG',
            value: 'STG',
        },
        {
            label: 'NON_PROD',
            value: 'NON_PROD',
        },
        {
            label: 'CORP',
            value: 'CORP',
        },
    ],
};

const allowFieldPath = 'source.config.path_specs';

export const XML_ADD_PATTERNS = {
    name: 'path_specs',
    label: 'XML / XSDS Path',
    tooltip:
        'Only include specific Databases by providing the name of a Database, or a Regular Expression (REGEX). If not provided, all Databases will be included.',
    configs: [
        {
            placeholder: 'Add xml path',
            name: 'include',
            key: 'xml-files.include',
            rules: [{ required: true, message: 'Missing xml path' }],
        },
        {
            placeholder: 'Add xsds path',
            name: 'xsds_path',
            key: 'xml-files.xsds_path',
            rules: [{ required: true, message: 'Missing xsds path' }],
        },
    ],
    buttonLabel: 'Add File Path',
    type: FieldType.MULTI_LIST,
    fieldPath: allowFieldPath,
    rules: null,
    setValueOnRecipeOverride: (recipe: any, values: string[]) => setListValuesOnRecipe(recipe, values, allowFieldPath),
};
