import { GenericEntityProperties } from '../../../types';
import { Entity } from '../../../../../../types.generated';

export function getInitialEntitiesForUrnPrompt(
    structuredPropertyUrn: string,
    entityData: GenericEntityProperties | null,
    selectedValues: any[],
) {
    const structuredPropertyEntry = entityData?.structuredProperties?.properties?.find(
        (p) => p.structuredProperty.urn === structuredPropertyUrn,
    );
    const entities = structuredPropertyEntry?.valueEntities?.filter((e) => selectedValues.includes(e?.urn));
    return entities ? (entities as Entity[]) : [];
}
