import React, { Fragment } from 'react';
import { ApiOutlined, FilterOutlined, QuestionCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Collapse, Form, message, Tooltip, Typography } from 'antd';
import styled from 'styled-components/macro';
import { get } from 'lodash';
import YAML from 'yamljs';

import { RecipeSections, RECIPE_FIELDS, CONNECTORS_WITH_TEST_CONNECTION } from './constants';
import { RecipeField, setFieldValueOnRecipe, APPLIANCE_INGESTION } from './common';
import { XML_TYPE_PLATFORM, XML_TYPE_PLATFORM_INSTANCE } from './xml';

import TestConnectionButton from './TestConnection/TestConnectionButton';
import { SourceBuilderState, SourceConfig } from '../types';
import { jsonToYaml } from '../../utils';
import FormField from './FormField';

import { useApplianceListQuery } from '../../../../../graphql/appliance.generated';
import { useListSecretsQuery } from '../../../../../graphql/ingestion.generated';
import { useListDataSourcesQuery } from '../../../../../graphql/datasource.generated';

export const ControlsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
`;

export const StyledCollapse = styled(Collapse)`
    margin-bottom: 16px;

    .ant-collapse-header {
        font-size: 14px;
        font-weight: bold;
        padding: 12px 0;
    }
`;

const HeaderTitle = styled.span`
    margin-left: 8px;
`;

const MarginWrapper = styled.div`
    margin-left: 20px;
`;

const TestConnectionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
`;

const HeaderTooltipWrapper = styled(QuestionCircleOutlined)`
    margin-left: 5px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
    cursor: help;
`;

const APPLIANCE_ID = 'applianceId';

function getInitialValues(displayRecipe: string, allFields: any[], applianceId, form) {
    const initialValues = {};
    let recipeObj;
    try {
        recipeObj = YAML.parse(displayRecipe);
    } catch (e) {
        message.warn('Found invalid YAML. Please check your recipe configuration.');
        return {};
    }
    if (recipeObj) {
        allFields.forEach((field) => {
            initialValues[field.name] = field.getValueFromRecipeOverride
                ? field.getValueFromRecipeOverride(recipeObj)
                : get(recipeObj, field.fieldPath);
        });
    }
    // Sets the initial value of the appliance ID
    if (applianceId) {
        form.setFieldsValue({ [APPLIANCE_ID]: applianceId });
    }

    return initialValues;
}

export function SectionHeader({ icon, text, sectionTooltip }: { icon: any; text: string; sectionTooltip?: string }) {
    return (
        <span>
            {icon}
            <HeaderTitle>{text}</HeaderTitle>
            {sectionTooltip && (
                <Tooltip placement="top" title={sectionTooltip}>
                    <HeaderTooltipWrapper />
                </Tooltip>
            )}
        </span>
    );
}

function shouldRenderFilterSectionHeader(field: RecipeField, index: number, filterFields: RecipeField[]) {
    if (index === 0 && field.section) return true;
    if (field.section && filterFields[index - 1].section !== field.section) return true;
    return false;
}

interface Props {
    state: SourceBuilderState;
    isEditing: boolean;
    displayRecipe: string;
    sourceConfigs?: SourceConfig;
    applianceId: string;
    setStagedRecipe: (recipe: string) => void;
    setApplianceId: (id: string) => void;
    onClickNext: () => void;
    goToPrevious?: () => void;
}

function RecipeForm(props: Props) {
    const {
        state,
        isEditing,
        applianceId,
        displayRecipe,
        sourceConfigs,
        setStagedRecipe,
        setApplianceId,
        onClickNext,
        goToPrevious,
    } = props;
    const { type } = state;
    const version = state.config?.version;
    const {
        fields,
        advancedFields,
        filterFields,
        fileLocations = [],
        filterSectionTooltip,
        advancedSectionTooltip,
        defaultOpenSections,
    } = RECIPE_FIELDS[type as string];
    const allFields = [...fields, ...advancedFields, ...filterFields, ...fileLocations];

    const { data, refetch: refetchSecrets } = useListSecretsQuery({
        variables: {
            input: {
                start: 0,
                count: 1000, // get all secrets
            },
        },
    });

    // This query fetch the appliance list for dropdown
    const { data: applianceData, refetch: refetchAppliance } = useApplianceListQuery({});

    // This query fetch the platform instances for the selected platform in the XML source
    const { refetch: getListDataSources } = useListDataSourcesQuery({
        skip: true,
    });

    const secrets =
        data?.listSecrets?.secrets.sort((secretA, secretB) => secretA.name.localeCompare(secretB.name)) || [];
    const [form] = Form.useForm();

    // Making the appliance list format compatible with the select component.
    APPLIANCE_INGESTION.setOptions(applianceData?.applianceList);

    // Helper function for fetching platform instances
    async function fetchXmlPlatformInstances(platform: string) {
        try {
            const { data: datasources } = await getListDataSources({
                input: {
                    start: 0,
                    count: 1000,
                    dsConnectorType: 'onprem',
                    connectorTypeId: [platform],
                },
            });

            // Replace with actual API call
            return datasources?.listDataSources || [];
        } catch (e) {
            return [];
        }
    }

    async function updateFormValues(changedValues: any, allValues: any) {
        let updatedValues = YAML.parse(displayRecipe);

        if (changedValues[XML_TYPE_PLATFORM]) {
            try {
                const xmlResults = await fetchXmlPlatformInstances(changedValues[XML_TYPE_PLATFORM]);

                // Find the `xml.platform_instance` field and update its options dynamically
                fields.forEach((field, index) => {
                    if (field.name === XML_TYPE_PLATFORM_INSTANCE) {
                        form.setFieldValue(XML_TYPE_PLATFORM_INSTANCE, null);

                        // Disable the field if there are no platform instances
                        fields[index].disabled = xmlResults.length === 0;

                        fields[index].options = [];
                        fields[index].setOptions(xmlResults);
                    }
                });
            } catch (e) {
                console.error('Error fetching platform instances:', e);
            }
        }

        Object.keys(changedValues).forEach((fieldName) => {
            const recipeField = allFields.find((f) => f.name === fieldName);

            if (recipeField) {
                updatedValues = recipeField.setValueOnRecipeOverride
                    ? recipeField.setValueOnRecipeOverride(updatedValues, allValues[fieldName])
                    : setFieldValueOnRecipe(updatedValues, allValues[fieldName], recipeField.fieldPath);
            }
        });

        if (changedValues[APPLIANCE_ID]) {
            setApplianceId(changedValues[APPLIANCE_ID]);
        }

        const stagedRecipe = jsonToYaml(JSON.stringify(updatedValues));
        setStagedRecipe(stagedRecipe);
    }

    function updateFormValue(fieldName, fieldValue) {
        updateFormValues({ [fieldName]: fieldValue }, { [fieldName]: fieldValue });
        form.setFieldsValue({ [fieldName]: fieldValue });
    }

    return (
        <Form
            layout="vertical"
            initialValues={getInitialValues(displayRecipe, allFields, applianceId, form)}
            onFinish={onClickNext}
            form={form}
            onValuesChange={updateFormValues}
        >
            <StyledCollapse defaultActiveKey="0">
                <Collapse.Panel
                    forceRender
                    header={<SectionHeader icon={<ApiOutlined aria-hidden="true" />} text="Pods" />}
                    key="0"
                >
                    {/* TODO this was showing Error Property 'updateFormValue' is missing in type  need Anas input to check this */}
                    <FormField
                        field={APPLIANCE_INGESTION}
                        secrets={secrets}
                        updateFormValue={updateFormValue}
                        refetchSecrets={refetchAppliance}
                    />
                </Collapse.Panel>
            </StyledCollapse>
            <StyledCollapse defaultActiveKey={defaultOpenSections?.includes(RecipeSections.Connection) ? '1' : ''}>
                <Collapse.Panel
                    forceRender
                    header={<SectionHeader icon={<ApiOutlined aria-hidden="true" />} text="Connection" />}
                    key="1"
                >
                    {fields.map((field, i) => (
                        <FormField
                            field={field}
                            secrets={secrets}
                            refetchSecrets={refetchSecrets}
                            removeMargin={i === fields.length - 1}
                            updateFormValue={updateFormValue}
                        />
                    ))}

                    {CONNECTORS_WITH_TEST_CONNECTION.has(type as string) && (
                        <TestConnectionWrapper>
                            <TestConnectionButton
                                type={type || ''}
                                applianceId={applianceId}
                                recipe={displayRecipe}
                                sourceConfigs={sourceConfigs}
                                version={version}
                            />
                        </TestConnectionWrapper>
                    )}
                </Collapse.Panel>
            </StyledCollapse>

            {filterFields.length > 0 && (
                <StyledCollapse defaultActiveKey={defaultOpenSections?.includes(RecipeSections.Filter) ? '2' : ''}>
                    <Collapse.Panel
                        forceRender
                        header={
                            <SectionHeader
                                icon={<FilterOutlined aria-hidden="true" />}
                                text="Filter"
                                sectionTooltip={filterSectionTooltip}
                            />
                        }
                        key="2"
                    >
                        {filterFields.map((field, i) => (
                            <Fragment key={field.name}>
                                {shouldRenderFilterSectionHeader(field, i, filterFields) && (
                                    <Typography.Title level={4}>{field.section}</Typography.Title>
                                )}
                                <MarginWrapper>
                                    <FormField
                                        field={field}
                                        secrets={secrets}
                                        refetchSecrets={refetchSecrets}
                                        removeMargin={i === filterFields.length - 1}
                                        updateFormValue={updateFormValue}
                                    />
                                </MarginWrapper>
                            </Fragment>
                        ))}
                    </Collapse.Panel>
                </StyledCollapse>
            )}

            {advancedFields.length > 0 && (
                <StyledCollapse defaultActiveKey={defaultOpenSections?.includes(RecipeSections.Advanced) ? '3' : ''}>
                    <Collapse.Panel
                        forceRender
                        header={
                            <SectionHeader
                                icon={<SettingOutlined aria-hidden="true" />}
                                text="Advanced"
                                sectionTooltip={advancedSectionTooltip}
                            />
                        }
                        key="3"
                    >
                        {advancedFields.map((field, i) => (
                            <FormField
                                field={field}
                                secrets={secrets}
                                refetchSecrets={refetchSecrets}
                                removeMargin={i === advancedFields.length - 1}
                                updateFormValue={updateFormValue}
                            />
                        ))}
                    </Collapse.Panel>
                </StyledCollapse>
            )}

            {fileLocations.length > 0 && (
                <StyledCollapse defaultActiveKey={defaultOpenSections?.includes(RecipeSections.Advanced) ? '4' : ''}>
                    <Collapse.Panel
                        forceRender
                        header={
                            <SectionHeader
                                icon={<SettingOutlined aria-hidden="true" />}
                                text="File Path"
                                sectionTooltip={advancedSectionTooltip}
                            />
                        }
                        key="4"
                    >
                        {fileLocations.map((field, i) => (
                            <FormField
                                field={field}
                                secrets={secrets}
                                refetchSecrets={refetchSecrets}
                                removeMargin={i === advancedFields.length - 1}
                                updateFormValue={updateFormValue}
                            />
                        ))}
                    </Collapse.Panel>
                </StyledCollapse>
            )}
            <ControlsContainer>
                <Button disabled={isEditing} onClick={goToPrevious}>
                    Previous
                </Button>
                <Button disabled={!applianceId} htmlType="submit">
                    Next
                </Button>
            </ControlsContainer>
        </Form>
    );
}

export default RecipeForm;
