import { Typography, message } from 'antd';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import { EntityType } from '../../../../../../types.generated';

import { useUpdateSubTypeMutation } from '../../../../../../graphql/mutations.generated';
import { useEntityData, useRefetch } from '../../../EntityContext';

const EntityTitle = styled(Typography.Title)`
    margin-right: 10px;
    width: fit-content;

    &&& {
        margin-bottom: 0;
        margin-top: 10px;
        word-break: break-all;
    }

    .ant-typography-edit {
        font-size: 16px;
        margin-left: 10px;
    }
`;

interface Props {
    isNameEditable?: boolean;
}

const ADD_SUBTYPE = 'Add SubType';

function EntitySubType(props: Props) {
    const { urn, entityType, entityData } = useEntityData();
    const { isNameEditable } = props;
    const [updatedSubType, setUpdatedSubType] = useState('');

    const [isEditing, setIsEditing] = useState(false);
    const refetch = useRefetch();

    const [updateSubType, { loading: isLoading }] = useUpdateSubTypeMutation();

    const subTypeName = entityData?.subTypes?.typeNames?.[0] || '';

    useEffect(() => {
        setUpdatedSubType(subTypeName);
    }, [subTypeName]);

    const handleStartEditing = () => {
        setIsEditing(true);
    };

    const handleChangeName = (name: string) => {
        const isDefaultValue = !entityData?.subTypes && name === ADD_SUBTYPE;

        if (name === subTypeName || isDefaultValue) {
            setIsEditing(false);
            return;
        }

        setUpdatedSubType(name);
        updateSubType({ variables: { input: { subType: name, urn } } })
            .then(() => {
                setIsEditing(false);
                message.success({ content: 'SubType Updated', duration: 2 });
                refetch();
            })
            .catch((e: unknown) => {
                message.destroy();
                if (e instanceof Error) {
                    message.error({ content: `Failed to update SubType: \n ${e.message || ''}`, duration: 3 });
                }
            });
    };

    // This will only be visible to Glossary Term and Glossary Node
    const isGlossary = [EntityType.GlossaryTerm, EntityType.GlossaryNode].includes(entityType);

    const renderEntity = () => {
        return isNameEditable || !entityData?.subTypes ? (
            <EntityTitle
                level={5}
                italic={!updatedSubType}
                disabled={isLoading}
                className={!updatedSubType ? 'f-color-blue-s100' : ''}
                editable={{
                    editing: isEditing,
                    onChange: handleChangeName,
                    onStart: handleStartEditing,
                }}
            >
                {updatedSubType || ADD_SUBTYPE}
            </EntityTitle>
        ) : (
            <EntityTitle level={5}>{subTypeName}</EntityTitle>
        );
    };
    return isGlossary ? renderEntity() : <></>;
}

export default EntitySubType;
