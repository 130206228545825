/* eslint-disable */
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListDataSourcesQueryVariables = Types.Exact<{
    input: Types.DataSourceListInput;
}>;

export type ListDataSourcesQuery = { __typename?: 'Query' } & {
    listDataSources?: Types.Maybe<
        Array<
            Types.Maybe<
                { __typename?: 'DataSource' } & Pick<
                    Types.DataSource,
                    'id' | 'name' | 'dsConnectorType' | 'connectorTypeId' | 'dataType' | 'state'
                >
            >
        >
    >;
};

export const ListDataSourcesDocument = gql`
    query listDataSources($input: DataSourceListInput!) {
        listDataSources(input: $input) {
            id
            name
            dsConnectorType
            connectorTypeId
            dataType
            state
        }
    }
`;

/**
 * __useListDataSourcesQuery__
 *
 * To run a query within a React component, call `useListDataSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDataSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDataSourcesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListDataSourcesQuery(
    baseOptions: Apollo.QueryHookOptions<ListDataSourcesQuery, ListDataSourcesQueryVariables>,
) {
    return Apollo.useQuery<ListDataSourcesQuery, ListDataSourcesQueryVariables>(ListDataSourcesDocument, baseOptions);
}
export function useListDataSourcesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ListDataSourcesQuery, ListDataSourcesQueryVariables>,
) {
    return Apollo.useLazyQuery<ListDataSourcesQuery, ListDataSourcesQueryVariables>(
        ListDataSourcesDocument,
        baseOptions,
    );
}
export type ListDataSourcesQueryHookResult = ReturnType<typeof useListDataSourcesQuery>;
export type ListDataSourcesLazyQueryHookResult = ReturnType<typeof useListDataSourcesLazyQuery>;
export type ListDataSourcesQueryResult = Apollo.QueryResult<ListDataSourcesQuery, ListDataSourcesQueryVariables>;
