import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { ArrowRightOutlined } from '@ant-design/icons';
import UserAvatarColumn from './UserAvatarColumn';
import TextColumn from './TextColumn';
import ActivityTagColumn from './ActivityTagColumn';
import { SingleAssetAuditResult } from '../../../../../../types.generated';
import TimeColumn from './TimeColumn';

function getValue(value) {
    return value?.replace(/&nbsp;/g, ' ');
}

export const columns: ColumnsType<SingleAssetAuditResult> = [
    {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        width: '10%',
        render: (value) => <TimeColumn value={value} />,
    },
    {
        title: 'Activity',
        key: 'activity',
        width: '10%',
        dataIndex: 'activity',
        render: (activity) => <ActivityTagColumn activity={activity} />,
    },
    {
        title: 'Attribute',
        dataIndex: 'attribute',
        key: 'attribute',
        width: '10%',
    },
    {
        title: 'Old Value',
        dataIndex: 'oldValue',
        key: 'oldValue',
        width: '10%',
        render: (value) => <TextColumn value={value} />,
    },
    {
        title: <div style={{ display: 'none' }}>Changed to</div>,
        dataIndex: 'arrowIcon',
        key: 'arrowIcon',
        width: '5%',
        align: 'center',
        render: () => <ArrowRightOutlined className="f-color-dark-black-s30" />,
    },
    {
        title: 'New Value',
        dataIndex: 'newValue',
        key: 'newValue',
        width: '10%',
        render: (value) => <TextColumn value={getValue(value)} />,
    },
    {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
        width: '30%',
        fixed: true,
        render: (value) => <TextColumn value={value} />,
    },
    {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
        width: '15%',
        render: (userUrn) => <UserAvatarColumn userUrn={userUrn} />,
    },
];
