import { PlusOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { TermRelationshipType, EntityType } from '../../../../types.generated';
import { Message } from '../../../shared/Message';
import { EmptyTab } from '../../shared/components/styled/EmptyTab';
import { ANTD_GRAY } from '../../shared/constants';
import AddRelatedTermsModal from './AddRelatedTermsModal';
import RelatedTerm from './RelatedTerm';

export enum RelatedTermTypes {
    hasRelatedTerms = 'Contains',
    isRelatedTerms = 'Inherits',
    containedBy = 'Contained by',
    isAChildren = 'Inherited by',
    typeOfRelations = 'Type Of',
    isTypeOf = 'Has Type',
}

export type Props = {
    glossaryRelatedTermType: string;
    glossaryRelatedTermResult: Array<any>;
};

type GlossaryRelatedTerm = {
    urn: string;
    type: string;
};

const ListContainer = styled.div`
    width: 100%;
`;

const TitleContainer = styled.div`
    align-items: center;
    border-bottom: solid 1px ${ANTD_GRAY[4]};
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    margin-bottom: 30px;
`;

const messageStyle = { marginTop: '10%' };

export default function GlossaryRelatedTermsResult({ glossaryRelatedTermType, glossaryRelatedTermResult }: Props) {
    const [isShowingAddModal, setIsShowingAddModal] = useState(false);
    const glossaryRelatedTerms: Array<GlossaryRelatedTerm> = [];

    glossaryRelatedTermResult.forEach((item: any) => {
        glossaryRelatedTerms.push({ urn: item?.entity?.urn, type: item?.entity?.type });
    });
    const contentLoading = false;
    const relationshipType =
        glossaryRelatedTermType === RelatedTermTypes.hasRelatedTerms ||
        glossaryRelatedTermType === RelatedTermTypes.containedBy
            ? TermRelationshipType.HasA
            : TermRelationshipType.IsA;
    const canEditRelatedTerms =
        glossaryRelatedTermType === RelatedTermTypes.isRelatedTerms ||
        glossaryRelatedTermType === RelatedTermTypes.hasRelatedTerms ||
        glossaryRelatedTermType === RelatedTermTypes.typeOfRelations;

    const getEntityType = (entityType: string) => {
        if (!entityType) {
            return EntityType.GlossaryTerm;
        }
        return entityType === EntityType.GlossaryTerm ? EntityType.GlossaryTerm : EntityType.GlossaryNode;
    };

    const isTypeOfBy = glossaryRelatedTermType === RelatedTermTypes.typeOfRelations;
    const addButtonText = isTypeOfBy ? 'Add Term Group / Terms' : 'Add Terms';

    return (
        <>
            {contentLoading ? (
                <Message type="loading" content="Loading..." style={messageStyle} />
            ) : (
                <ListContainer>
                    <TitleContainer>
                        <Typography.Title style={{ margin: '0' }} level={3}>
                            {glossaryRelatedTermType}
                        </Typography.Title>
                        {canEditRelatedTerms && (
                            <Button type="text" onClick={() => setIsShowingAddModal(true)} aria-label="Add Terms">
                                <PlusOutlined aria-hidden="true" /> {`${addButtonText}`}
                            </Button>
                        )}
                    </TitleContainer>
                    {glossaryRelatedTerms.map((entity) => (
                        <RelatedTerm
                            key={entity?.urn}
                            urn={entity?.urn}
                            entityType={getEntityType(entity?.type)}
                            relationshipType={relationshipType}
                            relatedEntityType={glossaryRelatedTermType}
                            isEditable={canEditRelatedTerms}
                        />
                    ))}
                    {glossaryRelatedTerms.length === 0 && (
                        <EmptyTab tab={glossaryRelatedTermType.toLocaleLowerCase()} />
                    )}
                </ListContainer>
            )}
            {isShowingAddModal && (
                <AddRelatedTermsModal
                    isTypeOfBy={isTypeOfBy}
                    entityType={EntityType.GlossaryTerm}
                    onClose={() => setIsShowingAddModal(false)}
                    relationshipType={relationshipType}
                />
            )}
        </>
    );
}
