import xmlLogo from '../../../../../images/snowflakelogo.png';

const placeholderRecipe = `\
source: 
    type: xml-files
    config:
        # Platform
        platform: "example_id"

        # Platform Instance
        platform_instance: "example_id"
        
        # Path Specification
        path_specs: "example_warehouse"
`;

export const XML = 'xml-files';

const xmlConfig = {
    type: XML,
    placeholderRecipe,
    displayName: 'Xml',
    logoUrl: xmlLogo,
};

export default xmlConfig;
