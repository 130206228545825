import { Button, DatePicker, Divider, Dropdown, Input, Select } from 'antd';
import React, { RefObject, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import { CloseCircleFilled } from '@ant-design/icons';

import { FilterOperator, Maybe } from '../../../../../types.generated';

import { ReactComponent as KeyboardArrowDown } from '../../../../../images/keyboard_arrow_down.svg';

const ApplyButton = styled(Button)`
    justify-content: center;
`;

const InnerWrapper = styled.div`
    display: flex;
    padding: 4px 8px;
`;

export enum FilterDropdownTypes {
    INPUT = 'input',
    TIME = 'time',
    DROP_DOWN = 'dropdown',
    CUSTOM = 'CUSTOM',
}

export interface IFilterDropdown {
    operators: Array<{ label: string; value: string | number }>;
    id: string;
    name: string;
    dropDownOptions?: Array<{ label: string; value: string | number }>;
    type?: FilterDropdownTypes;
    onResetFilter?: (key: string) => void;
    onApplyFilter?: (key: string, value: string | number, condition?: Maybe<FilterOperator>, negated?: boolean) => void;
    CustomRender?: React.FC<any>;
}
const FilterDropdown = (props: IFilterDropdown) => {
    const {
        id,
        name,
        operators,
        dropDownOptions = [],
        type,
        onApplyFilter = () => {},
        onResetFilter = () => {},
        CustomRender,
    } = props;

    const [fieldValue, setFieldValue] = useState<string | number | null | Moment | boolean>(null);
    const [isApplied, setIsApplied] = useState<boolean>(false);
    const [operator, setOperator] = useState<Maybe<FilterOperator>>(operators[0].value as FilterOperator);
    const dropdownRef: RefObject<HTMLDivElement> = useRef(null);
    const filterFieldRef: any = useRef(null); // Ref for the filter field
    const buttonRef: any = useRef(null); // Ref for the apply button

    const RenderComponent = CustomRender as React.FC<any>;

    const onChangeTextHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(event.target.value);
    };

    const onSelectHandler = (text: string) => {
        setFieldValue(text);
    };

    const onChangeDateHandler = (date: Moment | null) => {
        setFieldValue(!date ? null : moment(date).valueOf().toString());
    };

    const applyFilter = () => {
        onApplyFilter(id, fieldValue as string, operator);
        setIsApplied(true);
        dropdownRef.current?.click();
    };

    const resetFilter = (event: React.MouseEvent<HTMLInputElement>) => {
        event.stopPropagation();
        onResetFilter(id);
        setIsApplied(false);
        setFieldValue(null);
    };

    // This is a custom keydown handler to handle accessibility
    const handleFieldRef = (event) => {
        if (event.code === 'Tab') {
            event.preventDefault(); // Prevent default tabbing behavior
            event.stopPropagation(); // Stop event from bubbling and closing the dropdown

            if (event.target?.closest('.f-filter-dropdown-field')) {
                buttonRef.current?.focus(); // Use optional chaining to avoid errors
            } else {
                filterFieldRef.current?.focus(); // Use optional chaining
            }
        }
    };

    // This is a custom keydown handler to handle accessibility
    const handleCloseButton = (event) => {
        if (event.key === 'Enter') {
            resetFilter(event);
        }
    };

    const RenderInput = useMemo(() => {
        switch (type) {
            case FilterDropdownTypes.DROP_DOWN:
                return (
                    <Select
                        style={{ width: '100%' }}
                        ref={filterFieldRef}
                        className="f-filter-dropdown-field"
                        placeholder="Select the value"
                        aria-label="Select the value"
                        onKeyDown={handleFieldRef}
                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        value={fieldValue as string | number}
                        onChange={setFieldValue}
                    >
                        {dropDownOptions.map(({ label, value }) => (
                            <Select.Option value={value} tabIndex={0}>
                                {label}
                            </Select.Option>
                        ))}
                    </Select>
                );
            case FilterDropdownTypes.TIME:
                return (
                    <DatePicker
                        style={{ width: '100%' }}
                        value={fieldValue ? moment.unix((fieldValue as number) / 1000) : null}
                        showTime
                        ref={filterFieldRef}
                        className="f-filter-dropdown-field"
                        onChange={onChangeDateHandler}
                        onKeyDown={handleFieldRef}
                        format="MMM DD, YYYY h:mm a"
                    />
                );
            case FilterDropdownTypes.INPUT:
                return (
                    <Input
                        ref={filterFieldRef}
                        className="f-filter-dropdown-field"
                        placeholder="Enter keyword or phrase"
                        value={fieldValue as string}
                        onChange={onChangeTextHandler}
                        onKeyDown={handleFieldRef}
                    />
                );
            default:
                return (
                    <RenderComponent
                        value={fieldValue}
                        onChange={onSelectHandler}
                        handleFieldRef={handleFieldRef}
                        filterFieldRef={filterFieldRef}
                    />
                );
        }
    }, [dropDownOptions, fieldValue, type, RenderComponent]);

    return (
        <Dropdown
            trigger={['click']}
            className={`f-filter-dropdown ${isApplied && 'f-filter-applied'}`}
            dropdownRender={() => (
                <div className="f-filter-dropdown-menu">
                    <div className="pa-2">
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Select the operator"
                            aria-label="Select the operator"
                            getPopupContainer={(triggerNode) => triggerNode.parentNode}
                            onKeyDown={handleFieldRef} // Custom key handling
                            value={operator}
                            tabIndex={0}
                            onChange={setOperator}
                        >
                            {operators?.map(({ label, value }) => (
                                <Select.Option key={value} value={value} tabIndex={0}>
                                    {label}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <Divider style={{ margin: 0 }} />
                    <div className="pa-2" style={{ width: '100%' }}>
                        {RenderInput}
                    </div>
                    <div className="pa-2">
                        <ApplyButton
                            block
                            ref={buttonRef}
                            type="primary"
                            onClick={applyFilter}
                            disabled={!operator || !fieldValue}
                            tabIndex={0}
                        >
                            Apply
                        </ApplyButton>
                    </div>
                </div>
            )}
        >
            <Button ref={dropdownRef} type="link" tabIndex={0} aria-label={name}>
                <InnerWrapper>
                    <span>{name}</span>
                    <KeyboardArrowDown className="pl-2" style={{ width: 24 }} />
                    {isApplied && (
                        <CloseCircleFilled
                            tabIndex={0}
                            className="pl-1"
                            onKeyDown={handleCloseButton}
                            onClick={resetFilter}
                        />
                    )}
                </InnerWrapper>
            </Button>
        </Dropdown>
    );
};

export default FilterDropdown;
