import { message, Modal } from 'antd';
import React from 'react';
import { useBatchSetDomainMutation } from '../../../../graphql/mutations.generated';
import { DomainAssociation, SubResourceType } from '../../../../types.generated';
import { DomainLink } from '../DomainLink';

interface Props {
    domain: DomainAssociation;
    entityUrn?: string;
    entitySubresource?: string;
    canRemove?: boolean;
    readOnly?: boolean;
    onOpenModal?: () => void;
    refetch?: () => Promise<any>;
    fontSize?: number;
}

export default function Domain({
    domain,
    entityUrn,
    entitySubresource,
    canRemove,
    readOnly,
    onOpenModal,
    refetch,
    fontSize,
}: Props) {
    const [removeDomainMutation] = useBatchSetDomainMutation();
    const removeDomain = (domainAssociationToRemove: DomainAssociation) => {
        const domainToRemove = domainAssociationToRemove.domain;
        onOpenModal?.();
        Modal.confirm({
            title: `Do you want to remove ${domainToRemove?.properties?.name} domain?`,
            content: `Are you sure you want to remove the ${domainToRemove?.properties?.name} domain?`,
            onOk() {
                if (domainAssociationToRemove.associatedUrn || entityUrn) {
                    removeDomainMutation({
                        variables: {
                            input: {
                                resources: [
                                    {
                                        resourceUrn: domainAssociationToRemove.associatedUrn || entityUrn || '',
                                        subResource: entitySubresource,
                                        subResourceType: entitySubresource ? SubResourceType.DatasetField : null,
                                    },
                                ],
                            },
                        },
                    })
                        .then(({ errors }) => {
                            if (!errors) {
                                message.success({ content: 'Removed Domain!', duration: 2 });
                            }
                        })
                        .then(refetch)
                        .catch((e) => {
                            message.destroy();
                            message.error({ content: `Failed to remove domain: \n ${e.message || ''}`, duration: 3 });
                        });
                }
            },
            onCancel() {},
            okText: 'Yes',
            className: 'f-borderless-modal',
            okButtonProps: {
                type: undefined,
                danger: true,
            },
            autoFocusButton: null,
            maskClosable: true,
            centered: true,
        });
    };

    return (
        <DomainLink
            closable={canRemove && !readOnly}
            readOnly={readOnly}
            onClose={(e) => {
                e.preventDefault();
                removeDomain(domain);
            }}
            fontSize={fontSize}
            domain={domain.domain}
        />
    );
}
