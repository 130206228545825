import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { message, Button, Input, Modal, Typography, Form, Checkbox } from 'antd';

import NodeParentSelect from './NodeParentSelect';

import { useEntityRegistry } from '../../../useEntityRegistry';
import { useEntityData, useRefetch } from '../EntityContext';

import { useCloneGlossaryTermMutation } from '../../../../graphql/glossaryTerm.generated';
import { EntityType } from '../../../../types.generated';
import { useGlossaryEntityData } from '../GlossaryEntityContext';
import { getGlossaryRootToUpdate, updateGlossarySidebar } from '../../../glossary/utils';

const StyledItem = styled(Form.Item)`
    margin-bottom: 0;
`;

const OptionalWrapper = styled.span`
    font-weight: normal;
`;

interface Props {
    glossaryName: string;
    entityType: EntityType;
    onClose: () => void;
}

const aspectsList = [
    {
        key: 'DOCUMENTATION',
        value: 'Documentation',
    },
    {
        key: 'RELATED_ENTITIES',
        value: 'Related Entities',
    },
    {
        key: 'RELATED_TERMS',
        value: 'Related Terms',
    },
    {
        key: 'PROPERTIES',
        value: 'Properties',
    },
    {
        key: 'OWNERS',
        value: 'Owners',
    },
    {
        key: 'DOMAIN',
        value: 'Domain',
    },
    {
        key: 'GOVERNANCE_FLAGS',
        value: 'Governance Flags',
    },
    {
        key: 'GOVERNANCE_STATUS',
        value: 'Governance Status',
    },
    {
        key: 'SUBTYPE',
        value: 'Sub Type',
    },
];

function GlossaryCloneModal(props: Props) {
    const { entityType, onClose, glossaryName } = props;
    const { isInGlossaryContext, setUrnsToUpdate } = useGlossaryEntityData();

    const [cloneGlossaryTerm] = useCloneGlossaryTermMutation();
    const entityRegistry = useEntityRegistry();
    const entityData: any = useEntityData();
    const [form] = Form.useForm();
    const refetch = useRefetch();

    const [selectedParentUrn, setSelectedParentUrn] = useState('');

    const [createButtonDisabled, setCreateButtonDisabled] = useState(false);

    // default name for the cloned entity
    const [stagedName, setStagedName] = useState(`${glossaryName}_copy`);
    const [aspects, setAspects]: any = useState([]);

    // if the entity is a root node, the parent urn is empty
    const onParentRoot = entityData?.entityData?.parentNodes?.nodes.length === 0;

    // if the selected parent urn is empty, the parent urn is empty
    const isEmpty = selectedParentUrn.length === 0;

    function cloneGlossaryEntity() {
        let parentNode: any = null;

        if (onParentRoot && isEmpty) {
            // if the entity is a root node, set the parent node to null
            parentNode = null;
        } else {
            // if the selected parent urn is empty, set the parent node to null
            parentNode = !isEmpty ? selectedParentUrn : null;
        }

        cloneGlossaryTerm({
            variables: {
                input: {
                    urn: entityData?.urn,
                    name: stagedName,
                    aspects,
                    parentNode,
                },
            },
        })
            .then(() => {
                message.loading({ content: 'Updating...', duration: 2 });
                setTimeout(() => {
                    message.success({
                        content: `Cloned ${entityRegistry.getEntityName(entityType)}!`,
                        duration: 2,
                    });
                    refetch();

                    if (isInGlossaryContext) {
                        // either refresh this current glossary node or the root nodes or root terms
                        const nodeToUpdate = getGlossaryRootToUpdate(entityType);
                        updateGlossarySidebar([nodeToUpdate], [selectedParentUrn], setUrnsToUpdate);
                    }
                }, 2000);
            })
            .catch((e) => {
                message.destroy();
                message.error({ content: `Failed to create: \n ${e.message || ''}`, duration: 3 });
            });
        onClose();
    }

    return (
        <Modal
            title={`Clone ${entityRegistry.getEntityName(entityType)}`}
            open
            centered
            onCancel={onClose}
            footer={
                <>
                    <Button onClick={onClose} type="text">
                        Cancel
                    </Button>
                    <Button
                        onClick={cloneGlossaryEntity}
                        disabled={createButtonDisabled}
                        data-testid="glossary-entity-modal-create-button"
                    >
                        Create
                    </Button>
                </>
            }
        >
            <Form
                form={form}
                initialValues={{
                    parent: selectedParentUrn,
                    name: `${glossaryName}_copy`, // This is the default name for the cloned entity
                }}
                layout="vertical"
                onFieldsChange={() =>
                    setCreateButtonDisabled(form.getFieldsError().some((field) => field.errors.length > 0))
                }
            >
                <Form.Item label={<Typography.Text strong>Name</Typography.Text>}>
                    <StyledItem
                        data-testid="create-glossary-entity-modal-name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: `Enter a ${entityRegistry.getEntityName(entityType)} name.`,
                            },
                            { whitespace: true },
                            { min: 1, max: 100 },
                        ]}
                        hasFeedback
                    >
                        <Input autoFocus value={stagedName} onChange={(event) => setStagedName(event.target.value)} />
                    </StyledItem>
                </Form.Item>
                <Form.Item
                    label={
                        <Typography.Text strong>
                            Parent <OptionalWrapper>(optional)</OptionalWrapper>
                        </Typography.Text>
                    }
                >
                    <StyledItem name="parent">
                        <NodeParentSelect
                            selectedParentUrn={selectedParentUrn}
                            setSelectedParentUrn={setSelectedParentUrn}
                        />
                    </StyledItem>
                </Form.Item>
                <Form.Item
                    label={
                        <Typography.Text strong>
                            Aspects <OptionalWrapper>(optional)</OptionalWrapper>
                        </Typography.Text>
                    }
                >
                    <Checkbox.Group style={{ width: '100%' }} name="aspects" onChange={setAspects}>
                        {aspectsList.map((aspect) => (
                            <div className="mb-1" key={aspect.key}>
                                <Checkbox value={aspect.key}>{aspect.value}</Checkbox>
                            </div>
                        ))}
                    </Checkbox.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default GlossaryCloneModal;
