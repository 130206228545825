import React from 'react';
import { Tag, message } from 'antd';
import { useEntityData } from '../../../../EntityContext';

import { useRemoveGovernanceFlagsAspectMutation } from '../../../../../../../graphql/mutations.generated';
import { EditableSchemaMetadata, SchemaMetadata } from '../../../../../../../types.generated';

/**
 * This hook is used to render the governance flags for a field in the schema table
 * @param editableSchemaMetadata the editable schema metadata for the entity
 * @returns a function that can be used to render the governance flags for a field
 */
export default function useGovernanceFlagRenderer(
    editableSchemaMetadata: EditableSchemaMetadata | null | undefined,
    schemaMetadata: SchemaMetadata | null | undefined,
    refetch,
) {
    const { entityData } = useEntityData();

    const [removeGovernanceFlag] = useRemoveGovernanceFlagsAspectMutation();

    // Function to remove the governance flag for a field in the schema table
    const removeBatch = async (flag, subResource) => {
        try {
            await removeGovernanceFlag({
                variables: {
                    urn: entityData?.urn || '',
                    input: {
                        name: flag.name,
                        subResource,
                    },
                },
            });
            message.destroy();
            message.success({ content: 'Flag removed successfully', duration: 2 });
        } catch (e: unknown) {
            message.destroy();
        }
        refetch?.();
    };

    return (_: any, field: any): JSX.Element => {
        // Find the governance flags for the field in the editable schema metadata
        const editableGovernanceFlags = editableSchemaMetadata?.editableSchemaFieldInfo.find(
            (editableFieldInfo) => editableFieldInfo.fieldPath === field.fieldPath,
        );

        const unEditableGovernanceFlags = schemaMetadata?.fields?.find(
            (schemaFieldInfo) => schemaFieldInfo.fieldPath === field.fieldPath,
        );

        return (
            <>
                {editableGovernanceFlags?.governanceFlags?.flags?.map((flag, index) => (
                    <Tag
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${flag?.name}-${index}`}
                        closable
                        className="pri-tag"
                        onClose={(e) => {
                            e.preventDefault();
                            removeBatch(flag, editableGovernanceFlags?.fieldPath);
                        }}
                    >
                        {flag?.name || ''}
                    </Tag>
                ))}

                {unEditableGovernanceFlags?.governanceFlags?.flags?.map((flag, index) => (
                    <Tag
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${flag?.name}-${index}`}
                        className="pri-tag"
                    >
                        {flag?.name || ''}
                    </Tag>
                ))}
            </>
        );
    };
}
