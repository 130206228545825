import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components/macro';

import { useEntityData, useRefetch } from '../../../EntityContext';
import EntityDropdown, { EntityMenuItems } from '../../../EntityDropdown/EntityDropdown';
import PlatformContent from './PlatformContent';
import { getPlatformName } from '../../../utils';
import { EntityType, PlatformPrivileges } from '../../../../../../types.generated';
import EntityCount from './EntityCount';
import { EntityHealth } from './EntityHealth';
import EntityName from './EntityName';
import EntitySubType from './EntitySubType';

import { DeprecationPill } from '../../../components/styled/DeprecationPill';
import { GovernanceStatusPill } from '../../../components/styled/GovernanceStatusPill';
import { EntitySubHeaderSection, GenericEntityProperties } from '../../../types';
import EntityActions, { EntityActionItem } from '../../../entity/EntityActions';
import ExternalUrlButton from '../../../ExternalUrlButton';
import ShareButton from '../../../../../shared/share/ShareButton';
import { capitalizeFirstLetterOnly } from '../../../../../shared/textUtil';
import { useUserContext } from '../../../../../context/useUserContext';
import { useEntityRegistry } from '../../../../../useEntityRegistry';

import { GovernanceFlagAspectBatch } from '../../../components/styled/GovernanceFlagAspectBatch';

// const LogoContainer = styled.div`
//     && {
//         margin-right: 8px;
//
//         > div {
//             vertical-align: top;
//         }
//
//         img {
//             vertical-align: top;
//         }
//     }
// `;

const TitleWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;

    .ant-typography-edit-content {
        padding-top: 7px;
        margin-left: 15px;
    }
`;

// const HeaderContainer = styled.div`
//     display: flex;
//     flex-direction: row;
//     align-items: space-between;
//     margin-bottom: 4px;
// `;

const MainHeaderContent = styled(Col)``;

const SideHeaderContent = styled(Col)``;

const TopButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 8px;
`;

export function getCanEditName(
    entityType: EntityType,
    entityData: GenericEntityProperties | null,
    privileges?: PlatformPrivileges,
) {
    switch (entityType) {
        case EntityType.GlossaryTerm:
        case EntityType.GlossaryNode:
            return privileges?.manageGlossaries || !!entityData?.privileges?.canManageEntity;
        case EntityType.Domain:
            return privileges?.manageDomains;
        case EntityType.DataProduct:
            return true; // TODO: add permissions for data products
        default:
            return false;
    }
}

type Props = {
    headerDropdownItems?: Set<EntityMenuItems>;
    headerActionItems?: Set<EntityActionItem>;
    isNameEditable?: boolean;
    subHeader?: EntitySubHeaderSection;
    isExportable?: boolean;
    onExportClicked?: () => void;
};

export const EntityHeader = ({
    headerDropdownItems,
    headerActionItems,
    isNameEditable,
    subHeader,
    isExportable,
    onExportClicked,
}: Props) => {
    const { urn, entityType, entityData } = useEntityData();
    const refetch = useRefetch();
    const me = useUserContext();
    const platformName = getPlatformName(entityData);
    const externalUrl = entityData?.externalUrl || undefined;
    const entityCount = entityData?.entityCount;

    const entityName = entityData?.name;
    const subType = capitalizeFirstLetterOnly(entityData?.subTypes?.typeNames?.[0]) || undefined;

    const canEditName =
        isNameEditable && getCanEditName(entityType, entityData, me?.platformPrivileges as PlatformPrivileges);
    const entityRegistry = useEntityRegistry();

    return (
        <Row className="pa-4 f-pa-3-xs" data-testid="entity-header-test-id">
            <MainHeaderContent className="f-entity-drawer" xs={24} lg={16}>
                <PlatformContent />
                <TitleWrapper>
                    <EntityName isNameEditable={canEditName} />
                    {entityData?.deprecation?.deprecated && (
                        <DeprecationPill
                            urn={urn}
                            deprecation={entityData?.deprecation}
                            showUndeprecate
                            refetch={refetch}
                        />
                    )}
                    {entityData?.GovernanceStatusInfo && (
                        <GovernanceStatusPill governance={entityData.GovernanceStatusInfo} />
                    )}
                    {(entityData?.globalGovernanceFlag || entityData?.governanceFlags) && (
                        <GovernanceFlagAspectBatch
                            aspectFlag={entityData?.globalGovernanceFlag || entityData?.governanceFlags}
                            urn={urn}
                            refetch={refetch}
                        />
                    )}
                    {entityData?.health && (
                        <EntityHealth
                            health={entityData.health}
                            baseUrl={entityRegistry.getEntityUrl(entityType, urn)}
                        />
                    )}
                </TitleWrapper>

                <EntitySubType isNameEditable />

                <EntityCount entityCount={entityCount} displayAssetsText={entityType === EntityType.DataProduct} />
            </MainHeaderContent>
            {/* Giving this class to handle lineage drawer */}
            <SideHeaderContent xs={24} lg={8} className="side-header f-width-100-xs f-mt-3-xs">
                <TopButtonsWrapper>
                    {externalUrl && (
                        <ExternalUrlButton
                            externalUrl={externalUrl}
                            entityUrn={urn}
                            platformName={platformName}
                            entityType={entityType}
                        />
                    )}
                    {headerActionItems && (
                        <EntityActions urn={urn} actionItems={headerActionItems} refetchForEntity={refetch} />
                    )}
                    <ShareButton entityType={entityType} subType={subType} urn={urn} name={entityName} />
                    {headerDropdownItems && isExportable && (
                        <EntityDropdown
                            urn={urn}
                            entityType={entityType}
                            entityData={entityData}
                            menuItems={headerDropdownItems}
                            refetchForEntity={refetch}
                            onExportClicked={onExportClicked}
                        />
                    )}
                    {headerDropdownItems && !isExportable && (
                        <EntityDropdown
                            urn={urn}
                            entityType={entityType}
                            entityData={entityData}
                            menuItems={headerDropdownItems}
                            refetchForEntity={refetch}
                        />
                    )}
                </TopButtonsWrapper>
            </SideHeaderContent>
            {subHeader && <subHeader.component />}
        </Row>
    );
};
