import React from 'react';
import styled from 'styled-components';
import { Divider, Typography } from 'antd';

import TagTermGroup from '../../../../../shared/tags/TagTermGroup';
import { SidebarHeader } from './SidebarHeader';
import { useEntityData, useMutationUrn, useRefetch } from '../../../EntityContext';

const StyledDivider = styled(Divider)`
    margin: 16px 0;
`;

const SubTypContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
`;

const SubTypeNameText = styled(Typography.Text)`
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    color: #434343;
    margin-bottom: 8px;
`;

interface Props {
    properties?: any;
    readOnly?: boolean;
}

export const SidebarTagsSection = ({ properties, readOnly }: Props) => {
    const canAddTag = properties?.hasTags;
    const canAddTerm = properties?.hasTerms;

    const mutationUrn = useMutationUrn();

    const { entityType, entityData } = useEntityData();

    const refetch = useRefetch();

    const subTypeMapper: any = {
        Others: [],
    };

    const terms = entityData?.glossaryTerms?.terms;

    terms?.forEach((info) => {
        const subTypeKey = info?.term?.subTypes?.typeNames?.[0];

        /* eslint-disable @typescript-eslint/dot-notation */
        if (subTypeKey && !subTypeMapper[subTypeKey]) {
            subTypeMapper[subTypeKey] = [info];
        } else if (subTypeKey) {
            subTypeMapper[subTypeKey].push(info);
        } else if (!subTypeMapper['Others']) {
            subTypeMapper['Others'] = [info];
        } else subTypeMapper['Others'].push(info);
    });

    const subTypes = Object.keys(subTypeMapper).reverse();
    const subTypesLength = subTypes.length - 1;

    return (
        <>
            <div className="pa-4">
                <span>
                    <SidebarHeader title="Tags" />
                    <TagTermGroup
                        editableTags={entityData?.globalTags}
                        canAddTag={canAddTag}
                        canRemove
                        showEmptyMessage
                        entityUrn={mutationUrn}
                        entityType={entityType}
                        refetch={refetch}
                        readOnly={readOnly}
                        fontSize={12}
                    />
                </span>
            </div>
            <StyledDivider />
            <div className="pa-4">
                <span>
                    <SidebarHeader title="Glossary Terms" />
                    {subTypes.map((type, idx) => (
                        <SubTypContainer>
                            {subTypeMapper[type].length ? <SubTypeNameText>{type}</SubTypeNameText> : <></>}
                            <TagTermGroup
                                isSidebar
                                editableGlossaryTerms={{
                                    ...entityData?.glossaryTerms,
                                    terms: subTypeMapper[type] || [],
                                }}
                                canAddTerm={idx === subTypesLength && canAddTerm}
                                canRemove
                                showEmptyMessage={!terms?.length}
                                entityUrn={mutationUrn}
                                entityType={entityType}
                                refetch={refetch}
                                readOnly={readOnly}
                                fontSize={12}
                            />
                        </SubTypContainer>
                    ))}
                </span>
            </div>
        </>
    );
};
