import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Checkbox, Space, Row, Card, Divider, Menu, Dropdown } from 'antd';

import DatasetBulkLoadEntityModal from '../entity/shared/EntityDropdown/DatasetBulkLoadEntityModal';
import { Message } from '../shared/Message';

import { ReactComponent as DownloadOutlined } from '../../images/downloaded-outline.svg';
import { ReactComponent as DeleteOutlined } from '../../images/delete-outline.svg';
import { ReactComponent as UploadFile } from '../../images/upload-file.svg';
import { ReactComponent as MoreAction } from '../../images/more-vert.svg';
import { EntityXlsxExpectedSheetHeader } from '../entity/shared/types';

const GREY_COLOR = '#5F767C';
const DANGER_COLOR = '#d96758';

const styles = {
    row: { padding: '12px 16px 12px 24px' },
    title: { margin: 0 },
};

interface BrowseResultActionsProps {
    // Props definition
    isAllSelected: boolean;
    selectedItems: string[];
    isCsvExporting: boolean;
    exportCsv: () => void;
    handleSelectAll: (event) => void;
    confirmDatasetDeletion: () => void;
    expectedHeaders: EntityXlsxExpectedSheetHeader[];
}

export const BrowseDropDown = styled(Dropdown)`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const BrowseMenu = styled(Menu)`
    padding: 0px;
    border-radius: 4px;
    min-width: 140px !important;
`;

function BrowseResultActions({
    selectedItems,
    isAllSelected,
    isCsvExporting,
    handleSelectAll,
    confirmDatasetDeletion,
    exportCsv,
    expectedHeaders,
}: BrowseResultActionsProps) {
    const [isBulkLoadModalVisible, setIsBulkLoadModalVisible] = useState<boolean>(false);
    return (
        <>
            {isCsvExporting && <Message type="loading" content="Loading..." style={{ marginTop: '10%' }} />}
            <Card>
                <Row style={styles.row} justify="space-between" align="middle" className="f-px-4-xs">
                    <Space size="middle" align="center">
                        <Checkbox checked={isAllSelected} onChange={handleSelectAll}>
                            Select All
                        </Checkbox>
                    </Space>
                    <Row align="middle">
                        <Button
                            type="link"
                            className="mr-3"
                            onClick={() => setIsBulkLoadModalVisible(true)}
                            icon={<UploadFile style={{ width: 12 }} color={GREY_COLOR} />}
                        >
                            <span className="f-color-gray-s100">Bulk Import</span>
                        </Button>
                        <BrowseDropDown
                            overlay={
                                <BrowseMenu>
                                    <Menu.Item
                                        key="1"
                                        tabIndex={0}
                                        onClick={exportCsv}
                                        className="f-color-gray-s100"
                                        disabled={selectedItems.length === 0}
                                        icon={<DownloadOutlined style={{ width: 16 }} color="#5F767C" />}
                                    >
                                        Export
                                    </Menu.Item>
                                    <Divider className="ma-0" />
                                    <Menu.Item
                                        key="2"
                                        tabIndex={0}
                                        className="f-color-risk-very-high"
                                        disabled={selectedItems.length === 0}
                                        icon={<DeleteOutlined color={DANGER_COLOR} style={{ width: 10 }} />}
                                        onClick={confirmDatasetDeletion}
                                    >
                                        Delete Datasets
                                    </Menu.Item>
                                </BrowseMenu>
                            }
                            trigger={['click']}
                        >
                            <Row align="middle" style={{ gap: 8 }} tabIndex={0} role="button" aria-label="Actions">
                                <MoreAction
                                    data-testid="entity-header-dropdown"
                                    width={12}
                                    height={12}
                                    color={GREY_COLOR}
                                />
                                <span className="f-color-gray-s100">Actions</span>
                            </Row>
                        </BrowseDropDown>
                    </Row>
                </Row>
                <Divider className="ma-0" />
            </Card>

            {/* BulkLoad Entity Modal */}
            {isBulkLoadModalVisible && (
                <DatasetBulkLoadEntityModal
                    onClose={() => setIsBulkLoadModalVisible(false)}
                    expectedHeadersFromApi={expectedHeaders}
                />
            )}
        </>
    );
}

export default BrowseResultActions;
