import React from 'react';
import { Breadcrumb, Button, Card, Col, Row, Space } from 'antd';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { PageRoutes } from '../../conf/Global';
import { GlossaryNode, GlossaryTerm, ParentNodesResult } from '../../types.generated';
import { BreadcrumbItem } from '../entity/shared/containers/profile/nav/ProfileNavBrowsePath';
import { useGlossaryEntityData } from '../entity/shared/GlossaryEntityContext';
import { useEntityRegistry } from '../useEntityRegistry';
import { ReactComponent as ChangeLogIcon } from '../../images/icon-change-log.svg';
import { ReactComponent as RelationshipsIcon } from '../../images/icon-relationships.svg';
import { navigateToLineageUrl } from '../lineage/utils/navigateToLineageUrl';
import useIsChangeLogMode from '../entity/shared/containers/profile/utils/useIsChangeLogMode';

const BreadcrumbsWrapper = styled(Breadcrumb)`
    font-size: 16px;
`;

function getGlossaryBreadcrumbs(parentNodes: Maybe<ParentNodesResult>, entity: Maybe<GlossaryNode | GlossaryTerm>) {
    const nodes = parentNodes?.nodes;
    const breadcrumbs: (GlossaryTerm | GlossaryNode)[] = [...(nodes || [])].reverse();
    if (entity) breadcrumbs.push(entity);
    return breadcrumbs;
}
const GLOSSARY_TERM = 'GLOSSARY_TERM';

function GlossaryEntitiesPath() {
    const entityRegistry = useEntityRegistry();
    const { entityData } = useGlossaryEntityData();
    const history = useHistory();
    const location = useLocation();
    const isChangeLogMode = useIsChangeLogMode();

    const isGlossaryTerm = entityData?.type === GLOSSARY_TERM;
    const breadcrumbs = getGlossaryBreadcrumbs(entityData?.parentNodes, entityData as GlossaryNode | GlossaryTerm);

    // Open the instance explorer modal
    const onRelationshipsClick = () => {
        window.parent.postMessage(
            {
                action: 'openInstanceExplorerModal',
                urn: entityData?.urn,
                tableName: entityData?.properties?.name,
            },
            '*',
        );
    };

    return (
        <Row align="middle" className="mb-2">
            <Col flex="auto">
                <Card className="px-3 py-2">
                    <BreadcrumbsWrapper separator=">">
                        <BreadcrumbItem>
                            <Link to={PageRoutes.GLOSSARY}>Glossary</Link>
                        </BreadcrumbItem>
                        {breadcrumbs &&
                            breadcrumbs.map((node) => {
                                return (
                                    <BreadcrumbItem key={node.urn}>
                                        <Link to={`${entityRegistry.getEntityUrl(node.type, node.urn)}`}>
                                            {entityRegistry.getDisplayName(node.type, node)}
                                        </Link>
                                    </BreadcrumbItem>
                                );
                            })}
                    </BreadcrumbsWrapper>
                </Card>
            </Col>
            {isGlossaryTerm && (
                <Col flex="none" className="ml-2">
                    <Space size="middle">
                        <Button
                            type="primary"
                            icon={<ChangeLogIcon />}
                            className={`${isChangeLogMode ? 'f-btn-active' : ''} f-btn-underline`}
                            onClick={() => {
                                navigateToLineageUrl({
                                    location,
                                    history,
                                    isLineageMode: false,
                                    isChangeLogMode: true,
                                });
                            }}
                        >
                            Change Log
                        </Button>
                        <Button
                            type="primary"
                            icon={<RelationshipsIcon />}
                            className="f-btn-underline"
                            onClick={onRelationshipsClick}
                        >
                            Relationships
                        </Button>
                        <Button
                            type="primary"
                            aria-label="Details"
                            icon={<InfoCircleOutlined aria-hidden="true" />}
                            className={`${!isChangeLogMode ? 'f-btn-active' : ''} f-btn-underline`}
                            onClick={() => {
                                navigateToLineageUrl({
                                    location,
                                    history,
                                    isLineageMode: false,
                                    isChangeLogMode: false,
                                });
                            }}
                        >
                            Details
                        </Button>
                    </Space>
                </Col>
            )}
        </Row>
    );
}

export default GlossaryEntitiesPath;
