import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { List, Tag, Tooltip, Typography } from 'antd';
// import { Link } from 'react-router-dom';
import { CorpUser, CorpUserStatus, EntityType } from '../../../types.generated';
import CustomAvatar from '../../shared/avatar/CustomAvatar';
import { useEntityRegistry } from '../../useEntityRegistry';
import ViewResetTokenModal from './ViewResetTokenModal';

type Props = {
    user: CorpUser;
};

const UserItemContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 768px) {
        width: auto !important;
    }
`;

const UserHeaderContainer = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
`;

export default function UserListItem({ user }: Props) {
    const entityRegistry = useEntityRegistry();
    const [isViewingResetToken, setIsViewingResetToken] = useState(false);
    const displayName = entityRegistry.getDisplayName(EntityType.CorpUser, user);

    const getUserStatusToolTip = (userStatus: CorpUserStatus) => {
        switch (userStatus) {
            case CorpUserStatus.Active:
                return 'The user has logged in.';
            default:
                return '';
        }
    };

    // const getUserStatusColor = (userStatus: CorpUserStatus) => {
    //     switch (userStatus) {
    //         case CorpUserStatus.Active:
    //             return REDESIGN_COLORS.GREEN;
    //         default:
    //             return ANTD_GRAY[6];
    //     }
    // };

    const userStatus = user.status; // Support case where the user status is undefined.
    const userStatusToolTip = userStatus && getUserStatusToolTip(userStatus);
    const isActive = userStatus === CorpUserStatus.Active;

    return (
        <List.Item>
            <UserItemContainer>
                {/* TODO: Disabling the link for now */}
                {/* <Link to={entityRegistry.getEntityUrl(EntityType.CorpUser, user.urn)}> */}
                <UserHeaderContainer>
                    <CustomAvatar
                        size={32}
                        name={displayName}
                        photoUrl={user.editableProperties?.pictureLink || undefined}
                    />
                    <div className="pl-2 pr-3">
                        <Typography.Title className="f-text-big-content f-color-dark-black-s80 mb-0">
                            {displayName}
                        </Typography.Title>
                        <Typography.Title className="f-text-content f-color-dark-black-s50 my-0">
                            {user.username}
                        </Typography.Title>
                    </div>
                    {userStatus && (
                        <Tooltip overlay={userStatusToolTip}>
                            <Tag className={isActive ? 'green-tag' : ''}>{userStatus}</Tag>
                        </Tooltip>
                    )}
                </UserHeaderContainer>
                {/* </Link> */}
            </UserItemContainer>
            <ViewResetTokenModal
                visible={isViewingResetToken}
                userUrn={user.urn}
                username={user.username}
                onClose={() => setIsViewingResetToken(false)}
            />
        </List.Item>
    );
}
