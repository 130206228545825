import React, { useState } from 'react';
import { Button, message, Card, Dropdown, Menu } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { ReactComponent as MoreVertIcon } from '../../../../../images/icon-more-vert.svg';
import { ReactComponent as RefreshIcon } from '../../../../../images/icon-refresh.svg';

import { AddOrEditProperty, OperationType, DATA_PRODUCT, GLOSSARY_TERM, DOMAIN } from './AddOrEditProperty';
import { StyledTable } from '../../components/styled/StyledTable';
import { useEntityData, useRefetch } from '../../EntityContext';
import { EditColumn } from './Edit/EditColumn';
import ValuesColumn from './ValuesColumn';
import NameColumn from './NameColumn';

import useStructuredProperties, { getApplicableStructuredPropertyRows } from './useStructuredProperties';
import { getFilteredCustomProperties, mapCustomPropertiesToPropertyRows } from './utils';
import { useEntityRegistry } from '../../../../useEntityRegistry';
import { showDeleteModal } from '../../utils';
import { PropertyRow } from './types';

import { useDeleteDataProductPropertyMutation } from '../../../../../graphql/dataProduct.generated';
import { useDeleteGlossaryPropertyMutation } from '../../../../../graphql/glossary.generated';
import { useDeleteEntityPropertyMutation } from '../../../../../graphql/dataset.generated';
import { useDeleteDomainPropertyMutation } from '../../../../../graphql/domain.generated';
import {
    useGetApplicableStructuredPropertiesQuery,
    useRemoveStructuredPropertiesMutation,
} from '../../../../../graphql/structuredProperties.generated';

const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: right;
`;

const StyledMenu = styled(Menu)`
    min-width: 148px !important;
    border-radius: 4px;
    padding: 0;

    .ant-dropdown-menu-item {
        color: #4c4e54;
        padding: 8px 12px !important;
    }
`;

const DEFINITION = 'Definition';

export const PropertiesTab = () => {
    const { entityData }: any = useEntityData();
    const entityRegistry = useEntityRegistry();

    const refetch = useRefetch();

    const [operationType, setOperationType] = useState(OperationType.ADD);
    const [isResetEnabled, setIsResetEnabled] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editModalData, setEditModalData] = useState({});

    const [resetStructuredPropertiesMutation] = useRemoveStructuredPropertiesMutation();
    const [deleteDataProductPropertyMutation] = useDeleteDataProductPropertyMutation();
    const [deleteGlossaryPropertyMutation] = useDeleteGlossaryPropertyMutation();
    const [deleteDomainPropertyMutation] = useDeleteDomainPropertyMutation();
    const [deleteEntityProperty] = useDeleteEntityPropertyMutation();

    const { data } = useGetApplicableStructuredPropertiesQuery({
        variables: {
            input: {
                entityUrn: entityData?.urn,
            },
        },
        fetchPolicy: 'cache-first',
        skip: !entityData?.urn,
    });

    const applicableSp = data?.getApplicableStructuredProperties?.structuredProperties || [];

    const canAddProperty = [
        'DATASET',
        'CONTAINER',
        'DATA_PRODUCT',
        'DOMAIN',
        'CHART',
        'DASHBOARD',
        'DATA_FLOW',
        'DATA_JOB',
    ].includes(entityData?.type);

    const isGlossary = entityData?.type === GLOSSARY_TERM;
    const isCatalogEntity = [DATA_PRODUCT, DOMAIN].includes(entityData?.type);

    const isEditable = isGlossary ? entityData?.properties?.isEditable !== false : isCatalogEntity;

    // checks if the url is valid or not
    function isValidUrl(string) {
        try {
            const url = new URL(string);
            return url.protocol === 'http:' || url.protocol === 'https:';
        } catch (err) {
            return false;
        }
    }

    const getCustomProperties = (properties) => {
        return properties?.filter((p) => {
            if (p.key === DEFINITION) {
                return isValidUrl(p.value);
            }
            return true;
        });
    };

    // Opens the add or edit modal based on OperationType.
    const openAddOrEditModal = (type, record) => {
        setIsModalVisible(true);
        setOperationType(type);

        if (type === OperationType.EDIT) {
            setEditModalData(record);
        }
    };

    const getDeleteMutationFunc = (type) => {
        switch (type) {
            case DATA_PRODUCT:
                return deleteDataProductPropertyMutation;
            case GLOSSARY_TERM:
                return deleteGlossaryPropertyMutation;
            case DOMAIN:
                return deleteDomainPropertyMutation;
            default:
                return deleteEntityProperty;
        }
    };

    const resetStructuredProperty = (urn: string) => {
        setIsResetEnabled(true);

        resetStructuredPropertiesMutation({
            variables: {
                input: {
                    assetUrn: entityData?.urn,
                    structuredPropertyUrns: [urn],
                },
            },
        })
            .then(({ errors }: any) => {
                if (!errors) {
                    message.success('Structured properties reset successfully.');
                    refetch?.();
                }
            })
            .catch((e) => {
                message.destroy();
                message.error({
                    content: `Failed to reset the structured properties: \n ${e.message || ''}`,
                    duration: 3,
                });
            })
            .finally(() => {
                setIsResetEnabled(false);
            });
    };

    // Deletes a glossary property.
    const deleteGlossaryProperty = (key) => {
        // Checks if the entity is a glossary term. If it is, it will delete the glossary property.
        const deleteMutation = getDeleteMutationFunc(entityData?.type);

        deleteMutation({
            variables: {
                input: {
                    urn: entityData?.urn || '',
                    key,
                },
            },
        })
            .then(({ errors }: any) => {
                if (!errors) {
                    message.success('Property deleted successfully.');
                    refetch?.();
                }
            })
            .catch((e) => {
                message.destroy();
                message.error({
                    content: `Failed to delete the property: \n ${e.message || ''}`,
                    duration: 3,
                });
            });
    };

    const onConfirmDelete = (record) => {
        const content = `Are you sure you want to delete “${record.key}”? Please note that this action cannot be undone.`;
        showDeleteModal('Property', () => deleteGlossaryProperty(record.key), content);
    };

    const getPropertiesColumn = ({ isCustomProperty = true }): any => {
        return [
            {
                width: '35%',
                title: 'Name',
                ariaLabel: 'Name',
                defaultSortOrder: 'ascend',
                render: (propertyRow: PropertyRow) => <NameColumn propertyRow={propertyRow} />,
            },
            {
                title: 'Value',
                render: (propertyRow: PropertyRow) => <ValuesColumn propertyRow={propertyRow} />,
            },
            {
                width: '10%',
                title:
                    (isEditable || canAddProperty) && isCustomProperty ? (
                        <Button
                            size="small"
                            className="f-btn-underline"
                            onClick={() => openAddOrEditModal(OperationType.ADD, '')}
                        >
                            <PlusOutlined />
                            Add New Property
                        </Button>
                    ) : (
                        ''
                    ),
                render: (record: any) => {
                    if (record?.structuredProperty) {
                        return (
                            <ActionButtonContainer>
                                {record.values.length ? (
                                    <Button
                                        type="text"
                                        disabled={isResetEnabled}
                                        className="icon-button mr-0"
                                        onClick={() => resetStructuredProperty(record.structuredProperty.urn)}
                                        icon={<RefreshIcon />}
                                    />
                                ) : (
                                    <></>
                                )}

                                <EditColumn
                                    structuredProperty={record.structuredProperty}
                                    values={record.values?.map((v) => v.value) || []}
                                />
                            </ActionButtonContainer>
                        );
                    }

                    return isEditable || record?.isEditable ? (
                        <ActionButtonContainer>
                            <Dropdown
                                trigger={['click']}
                                overlay={
                                    <StyledMenu>
                                        <Menu.Item
                                            key="edit"
                                            onClick={() => openAddOrEditModal(OperationType.EDIT, record)}
                                        >
                                            Edit Property
                                        </Menu.Item>
                                        <Menu.Item key="delete" onClick={() => onConfirmDelete(record)}>
                                            Delete Property
                                        </Menu.Item>
                                    </StyledMenu>
                                }
                            >
                                <Button type="text" className="icon-button ma-0" icon={<MoreVertIcon />} />
                            </Dropdown>
                        </ActionButtonContainer>
                    ) : (
                        ''
                    );
                },
            },
        ];
    };

    const { structuredPropertyRows } = useStructuredProperties(entityRegistry, '');
    const customProperties = getFilteredCustomProperties('', entityData) || [];
    const customPropertyRows = mapCustomPropertiesToPropertyRows(getCustomProperties(customProperties), isEditable);

    // Combines the structured properties and custom properties.
    const getCombinedStructuredProperties = () => {
        const applicableProperties = getApplicableStructuredPropertyRows(applicableSp);

        const filteredProperties = applicableProperties?.filter((property: any) => {
            return !structuredPropertyRows?.some(
                (spRow: any) => spRow.structuredProperty.urn === property.structuredProperty.urn,
            );
        });

        return [...structuredPropertyRows, ...filteredProperties];
    };

    const structuredProperties = getCombinedStructuredProperties();

    return (
        <Card className="pa-4">
            {false && (
                <>
                    <div className="f-text-big-content f-text-single-line f-color-dark-black-s80 mb-2">
                        Standard Properties
                    </div>
                    <div className="f-text-medium-content f-color-dark-black-s50 mb-3">
                        Properties that have already been created and assigned to the entity.
                    </div>

                    <div className="f-border-all f-rounded-corner-1 mb-4">
                        <StyledTable
                            size="small"
                            pagination={false}
                            rowKey="standardProperties"
                            columns={getPropertiesColumn({ isCustomProperty: false })}
                            dataSource={structuredProperties}
                        />
                    </div>
                </>
            )}

            <div className="f-text-big-content f-text-single-line f-color-dark-black-s80 mb-2">Custom Properties</div>
            <div className="f-text-medium-content f-color-dark-black-s50 mb-3">
                Properties created and given values by the user.
            </div>

            <div className="f-border-all f-rounded-corner-1 overflow-hidden">
                <StyledTable
                    size="small"
                    pagination={false}
                    rowKey="customProperties"
                    columns={getPropertiesColumn({ isCustomProperty: true })}
                    dataSource={customPropertyRows}
                />

                <AddOrEditProperty
                    urn={entityData?.urn}
                    type={entityData?.type}
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    editModalData={editModalData || ''}
                    operationType={operationType}
                    refetch={refetch}
                />
            </div>
        </Card>
    );
};
