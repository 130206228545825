/* eslint-disable */
import * as Types from '../types.generated';

import {
    StructuredPropertiesFieldsFragment,
    InstitutionalMemoryFieldsFragment,
    OwnershipFieldsFragment,
    GlobalTagsFieldsFragment,
    GlossaryTermsFragment,
    EntityDomainFragment,
    PlatformFieldsFragment,
    EntityDataProduct_AccessTokenMetadata_Fragment,
    EntityDataProduct_Assertion_Fragment,
    EntityDataProduct_Chart_Fragment,
    EntityDataProduct_Container_Fragment,
    EntityDataProduct_CorpGroup_Fragment,
    EntityDataProduct_CorpUser_Fragment,
    EntityDataProduct_Dashboard_Fragment,
    EntityDataProduct_DataFlow_Fragment,
    EntityDataProduct_DataHubPolicy_Fragment,
    EntityDataProduct_DataHubRole_Fragment,
    EntityDataProduct_DataHubView_Fragment,
    EntityDataProduct_DataJob_Fragment,
    EntityDataProduct_DataPlatform_Fragment,
    EntityDataProduct_DataPlatformInstance_Fragment,
    EntityDataProduct_DataProcessInstance_Fragment,
    EntityDataProduct_DataProduct_Fragment,
    EntityDataProduct_DataTypeEntity_Fragment,
    EntityDataProduct_Dataset_Fragment,
    EntityDataProduct_Domain_Fragment,
    EntityDataProduct_EntityTypeEntity_Fragment,
    EntityDataProduct_GlossaryNode_Fragment,
    EntityDataProduct_GlossaryTerm_Fragment,
    EntityDataProduct_MlFeature_Fragment,
    EntityDataProduct_MlFeatureTable_Fragment,
    EntityDataProduct_MlModel_Fragment,
    EntityDataProduct_MlModelGroup_Fragment,
    EntityDataProduct_MlPrimaryKey_Fragment,
    EntityDataProduct_Notebook_Fragment,
    EntityDataProduct_OwnershipTypeEntity_Fragment,
    EntityDataProduct_Post_Fragment,
    EntityDataProduct_QueryEntity_Fragment,
    EntityDataProduct_Role_Fragment,
    EntityDataProduct_SchemaFieldEntity_Fragment,
    EntityDataProduct_StructuredPropertyEntity_Fragment,
    EntityDataProduct_Tag_Fragment,
    EntityDataProduct_Test_Fragment,
    EntityDataProduct_VersionedDataset_Fragment,
    NonRecursiveDataFlowFieldsFragment,
    DeprecationFieldsFragment,
    EmbedFieldsFragment,
    DataPlatformInstanceFieldsFragment,
    ParentContainersFieldsFragment,
    BrowsePathV2FieldsFragment,
    InputFieldsFieldsFragment,
    EntityContainerFragment,
    TypeOfRelationsFieldsFragment,
    ParentNodesFieldsFragment,
    GlossaryNodeFragment,
    GlossaryTermFragment,
    ParentDomainsFieldsFragment,
    DomainEntitiesFieldsFragment,
    NonRecursiveMlFeatureTableFragment,
    NonRecursiveMlFeatureFragment,
    NonRecursiveMlPrimaryKeyFragment,
    SchemaMetadataFieldsFragment,
    NonConflictingPlatformFieldsFragment,
    EntityDisplayNameFields_AccessTokenMetadata_Fragment,
    EntityDisplayNameFields_Assertion_Fragment,
    EntityDisplayNameFields_Chart_Fragment,
    EntityDisplayNameFields_Container_Fragment,
    EntityDisplayNameFields_CorpGroup_Fragment,
    EntityDisplayNameFields_CorpUser_Fragment,
    EntityDisplayNameFields_Dashboard_Fragment,
    EntityDisplayNameFields_DataFlow_Fragment,
    EntityDisplayNameFields_DataHubPolicy_Fragment,
    EntityDisplayNameFields_DataHubRole_Fragment,
    EntityDisplayNameFields_DataHubView_Fragment,
    EntityDisplayNameFields_DataJob_Fragment,
    EntityDisplayNameFields_DataPlatform_Fragment,
    EntityDisplayNameFields_DataPlatformInstance_Fragment,
    EntityDisplayNameFields_DataProcessInstance_Fragment,
    EntityDisplayNameFields_DataProduct_Fragment,
    EntityDisplayNameFields_DataTypeEntity_Fragment,
    EntityDisplayNameFields_Dataset_Fragment,
    EntityDisplayNameFields_Domain_Fragment,
    EntityDisplayNameFields_EntityTypeEntity_Fragment,
    EntityDisplayNameFields_GlossaryNode_Fragment,
    EntityDisplayNameFields_GlossaryTerm_Fragment,
    EntityDisplayNameFields_MlFeature_Fragment,
    EntityDisplayNameFields_MlFeatureTable_Fragment,
    EntityDisplayNameFields_MlModel_Fragment,
    EntityDisplayNameFields_MlModelGroup_Fragment,
    EntityDisplayNameFields_MlPrimaryKey_Fragment,
    EntityDisplayNameFields_Notebook_Fragment,
    EntityDisplayNameFields_OwnershipTypeEntity_Fragment,
    EntityDisplayNameFields_Post_Fragment,
    EntityDisplayNameFields_QueryEntity_Fragment,
    EntityDisplayNameFields_Role_Fragment,
    EntityDisplayNameFields_SchemaFieldEntity_Fragment,
    EntityDisplayNameFields_StructuredPropertyEntity_Fragment,
    EntityDisplayNameFields_Tag_Fragment,
    EntityDisplayNameFields_Test_Fragment,
    EntityDisplayNameFields_VersionedDataset_Fragment,
} from './fragments.generated';
import { gql } from '@apollo/client';
import {
    StructuredPropertiesFieldsFragmentDoc,
    InstitutionalMemoryFieldsFragmentDoc,
    OwnershipFieldsFragmentDoc,
    GlobalTagsFieldsFragmentDoc,
    GlossaryTermsFragmentDoc,
    EntityDomainFragmentDoc,
    PlatformFieldsFragmentDoc,
    EntityDataProductFragmentDoc,
    NonRecursiveDataFlowFieldsFragmentDoc,
    DeprecationFieldsFragmentDoc,
    EmbedFieldsFragmentDoc,
    DataPlatformInstanceFieldsFragmentDoc,
    ParentContainersFieldsFragmentDoc,
    BrowsePathV2FieldsFragmentDoc,
    InputFieldsFieldsFragmentDoc,
    EntityContainerFragmentDoc,
    TypeOfRelationsFieldsFragmentDoc,
    ParentNodesFieldsFragmentDoc,
    GlossaryNodeFragmentDoc,
    GlossaryTermFragmentDoc,
    ParentDomainsFieldsFragmentDoc,
    DomainEntitiesFieldsFragmentDoc,
    NonRecursiveMlFeatureTableFragmentDoc,
    NonRecursiveMlFeatureFragmentDoc,
    NonRecursiveMlPrimaryKeyFragmentDoc,
    SchemaMetadataFieldsFragmentDoc,
    NonConflictingPlatformFieldsFragmentDoc,
    EntityDisplayNameFieldsFragmentDoc,
} from './fragments.generated';
import * as Apollo from '@apollo/client';
export type GetDataProductQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type GetDataProductQuery = { __typename?: 'Query' } & {
    dataProduct?: Types.Maybe<
        { __typename?: 'DataProduct' } & {
            structuredProperties?: Types.Maybe<
                { __typename?: 'StructuredProperties' } & {
                    properties?: Types.Maybe<
                        Array<{ __typename?: 'StructuredPropertiesEntry' } & StructuredPropertiesFieldsFragment>
                    >;
                }
            >;
        } & DataProductFieldsFragment
    >;
};

export type ListDataProductsQueryVariables = Types.Exact<{
    input: Types.ListDataProductsInput;
}>;

export type ListDataProductsQuery = { __typename?: 'Query' } & {
    listDataProducts?: Types.Maybe<
        { __typename?: 'ListDataProductsResult' } & Pick<Types.ListDataProductsResult, 'start' | 'count' | 'total'> & {
                dataProducts: Array<
                    { __typename?: 'DataProduct' } & Pick<Types.DataProduct, 'urn' | 'type'> & DataProductFieldsFragment
                >;
            }
    >;
};

export type DataProductFieldsFragment = { __typename?: 'DataProduct' } & {
    properties?: Types.Maybe<
        { __typename?: 'DataProductProperties' } & {
            customProperties?: Types.Maybe<
                Array<{ __typename?: 'CustomPropertiesEntry' } & Pick<Types.CustomPropertiesEntry, 'key' | 'value'>>
            >;
        }
    >;
    institutionalMemory?: Types.Maybe<{ __typename?: 'InstitutionalMemory' } & InstitutionalMemoryFieldsFragment>;
} & DataProductSearchFieldsFragment;

export type DataProductSearchFieldsFragment = { __typename?: 'DataProduct' } & Pick<
    Types.DataProduct,
    'urn' | 'type'
> & {
        properties?: Types.Maybe<
            { __typename?: 'DataProductProperties' } & Pick<
                Types.DataProductProperties,
                'name' | 'description' | 'externalUrl'
            >
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        tags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
        domain?: Types.Maybe<{ __typename?: 'DomainAssociation' } & EntityDomainFragment>;
        entities?: Types.Maybe<{ __typename?: 'SearchResults' } & Pick<Types.SearchResults, 'total'>>;
        GovernanceStatusInfo?: Types.Maybe<
            { __typename?: 'GovernanceStatus' } & Pick<Types.GovernanceStatus, 'displayName' | 'doc' | 'status'> & {
                    created: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'actor' | 'time'>;
                }
        >;
    };

export type CreateDataProductMutationVariables = Types.Exact<{
    input: Types.CreateDataProductInput;
}>;

export type CreateDataProductMutation = { __typename?: 'Mutation' } & {
    createDataProduct?: Types.Maybe<{ __typename?: 'DataProduct' } & DataProductSearchFieldsFragment>;
};

export type UpdateDataProductMutationVariables = Types.Exact<{
    urn: Types.Scalars['String'];
    input: Types.UpdateDataProductInput;
}>;

export type UpdateDataProductMutation = { __typename?: 'Mutation' } & {
    updateDataProduct?: Types.Maybe<{ __typename?: 'DataProduct' } & DataProductSearchFieldsFragment>;
};

export type DeleteDataProductMutationVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type DeleteDataProductMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'deleteDataProduct'>;

export type BatchSetDataProductMutationVariables = Types.Exact<{
    input: Types.BatchSetDataProductInput;
}>;

export type BatchSetDataProductMutation = { __typename?: 'Mutation' } & Pick<Types.Mutation, 'batchSetDataProduct'>;

export type UpdateDataProductPropertyMutationVariables = Types.Exact<{
    input: Types.UpdateDataProductPropertyInfo;
}>;

export type UpdateDataProductPropertyMutation = { __typename?: 'Mutation' } & Pick<
    Types.Mutation,
    'updateDataProductProperty'
>;

export type DeleteDataProductPropertyMutationVariables = Types.Exact<{
    input: Types.DeleteDataProductPropertyInfo;
}>;

export type DeleteDataProductPropertyMutation = { __typename?: 'Mutation' } & Pick<
    Types.Mutation,
    'deleteDataProductProperty'
>;

export type SyncDataProductOwnersToChannelMutationVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type SyncDataProductOwnersToChannelMutation = { __typename?: 'Mutation' } & Pick<
    Types.Mutation,
    'syncDataProductOwnersToChannel'
>;

export const DataProductSearchFieldsFragmentDoc = gql`
    fragment dataProductSearchFields on DataProduct {
        urn
        type
        properties {
            name
            description
            externalUrl
        }
        ownership {
            ...ownershipFields
        }
        tags {
            ...globalTagsFields
        }
        glossaryTerms {
            ...glossaryTerms
        }
        domain {
            ...entityDomain
        }
        entities(input: { start: 0, count: 0, query: "*" }) {
            total
        }
        GovernanceStatusInfo {
            created {
                actor
                time
            }
            displayName
            doc
            status
        }
    }
    ${OwnershipFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
    ${GlossaryTermsFragmentDoc}
    ${EntityDomainFragmentDoc}
`;
export const DataProductFieldsFragmentDoc = gql`
    fragment dataProductFields on DataProduct {
        ...dataProductSearchFields
        properties {
            customProperties {
                key
                value
            }
        }
        institutionalMemory {
            ...institutionalMemoryFields
        }
    }
    ${DataProductSearchFieldsFragmentDoc}
    ${InstitutionalMemoryFieldsFragmentDoc}
`;
export const GetDataProductDocument = gql`
    query getDataProduct($urn: String!) {
        dataProduct(urn: $urn) {
            ...dataProductFields
            structuredProperties {
                properties {
                    ...structuredPropertiesFields
                }
            }
        }
    }
    ${DataProductFieldsFragmentDoc}
    ${StructuredPropertiesFieldsFragmentDoc}
`;

/**
 * __useGetDataProductQuery__
 *
 * To run a query within a React component, call `useGetDataProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataProductQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useGetDataProductQuery(
    baseOptions: Apollo.QueryHookOptions<GetDataProductQuery, GetDataProductQueryVariables>,
) {
    return Apollo.useQuery<GetDataProductQuery, GetDataProductQueryVariables>(GetDataProductDocument, baseOptions);
}
export function useGetDataProductLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetDataProductQuery, GetDataProductQueryVariables>,
) {
    return Apollo.useLazyQuery<GetDataProductQuery, GetDataProductQueryVariables>(GetDataProductDocument, baseOptions);
}
export type GetDataProductQueryHookResult = ReturnType<typeof useGetDataProductQuery>;
export type GetDataProductLazyQueryHookResult = ReturnType<typeof useGetDataProductLazyQuery>;
export type GetDataProductQueryResult = Apollo.QueryResult<GetDataProductQuery, GetDataProductQueryVariables>;
export const ListDataProductsDocument = gql`
    query listDataProducts($input: ListDataProductsInput!) {
        listDataProducts(input: $input) {
            start
            count
            total
            dataProducts {
                urn
                type
                ...dataProductFields
            }
        }
    }
    ${DataProductFieldsFragmentDoc}
`;

/**
 * __useListDataProductsQuery__
 *
 * To run a query within a React component, call `useListDataProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDataProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDataProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListDataProductsQuery(
    baseOptions: Apollo.QueryHookOptions<ListDataProductsQuery, ListDataProductsQueryVariables>,
) {
    return Apollo.useQuery<ListDataProductsQuery, ListDataProductsQueryVariables>(
        ListDataProductsDocument,
        baseOptions,
    );
}
export function useListDataProductsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ListDataProductsQuery, ListDataProductsQueryVariables>,
) {
    return Apollo.useLazyQuery<ListDataProductsQuery, ListDataProductsQueryVariables>(
        ListDataProductsDocument,
        baseOptions,
    );
}
export type ListDataProductsQueryHookResult = ReturnType<typeof useListDataProductsQuery>;
export type ListDataProductsLazyQueryHookResult = ReturnType<typeof useListDataProductsLazyQuery>;
export type ListDataProductsQueryResult = Apollo.QueryResult<ListDataProductsQuery, ListDataProductsQueryVariables>;
export const CreateDataProductDocument = gql`
    mutation createDataProduct($input: CreateDataProductInput!) {
        createDataProduct(input: $input) {
            ...dataProductSearchFields
        }
    }
    ${DataProductSearchFieldsFragmentDoc}
`;
export type CreateDataProductMutationFn = Apollo.MutationFunction<
    CreateDataProductMutation,
    CreateDataProductMutationVariables
>;

/**
 * __useCreateDataProductMutation__
 *
 * To run a mutation, you first call `useCreateDataProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataProductMutation, { data, loading, error }] = useCreateDataProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDataProductMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateDataProductMutation, CreateDataProductMutationVariables>,
) {
    return Apollo.useMutation<CreateDataProductMutation, CreateDataProductMutationVariables>(
        CreateDataProductDocument,
        baseOptions,
    );
}
export type CreateDataProductMutationHookResult = ReturnType<typeof useCreateDataProductMutation>;
export type CreateDataProductMutationResult = Apollo.MutationResult<CreateDataProductMutation>;
export type CreateDataProductMutationOptions = Apollo.BaseMutationOptions<
    CreateDataProductMutation,
    CreateDataProductMutationVariables
>;
export const UpdateDataProductDocument = gql`
    mutation updateDataProduct($urn: String!, $input: UpdateDataProductInput!) {
        updateDataProduct(urn: $urn, input: $input) {
            ...dataProductSearchFields
        }
    }
    ${DataProductSearchFieldsFragmentDoc}
`;
export type UpdateDataProductMutationFn = Apollo.MutationFunction<
    UpdateDataProductMutation,
    UpdateDataProductMutationVariables
>;

/**
 * __useUpdateDataProductMutation__
 *
 * To run a mutation, you first call `useUpdateDataProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataProductMutation, { data, loading, error }] = useUpdateDataProductMutation({
 *   variables: {
 *      urn: // value for 'urn'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataProductMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateDataProductMutation, UpdateDataProductMutationVariables>,
) {
    return Apollo.useMutation<UpdateDataProductMutation, UpdateDataProductMutationVariables>(
        UpdateDataProductDocument,
        baseOptions,
    );
}
export type UpdateDataProductMutationHookResult = ReturnType<typeof useUpdateDataProductMutation>;
export type UpdateDataProductMutationResult = Apollo.MutationResult<UpdateDataProductMutation>;
export type UpdateDataProductMutationOptions = Apollo.BaseMutationOptions<
    UpdateDataProductMutation,
    UpdateDataProductMutationVariables
>;
export const DeleteDataProductDocument = gql`
    mutation deleteDataProduct($urn: String!) {
        deleteDataProduct(urn: $urn)
    }
`;
export type DeleteDataProductMutationFn = Apollo.MutationFunction<
    DeleteDataProductMutation,
    DeleteDataProductMutationVariables
>;

/**
 * __useDeleteDataProductMutation__
 *
 * To run a mutation, you first call `useDeleteDataProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataProductMutation, { data, loading, error }] = useDeleteDataProductMutation({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useDeleteDataProductMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteDataProductMutation, DeleteDataProductMutationVariables>,
) {
    return Apollo.useMutation<DeleteDataProductMutation, DeleteDataProductMutationVariables>(
        DeleteDataProductDocument,
        baseOptions,
    );
}
export type DeleteDataProductMutationHookResult = ReturnType<typeof useDeleteDataProductMutation>;
export type DeleteDataProductMutationResult = Apollo.MutationResult<DeleteDataProductMutation>;
export type DeleteDataProductMutationOptions = Apollo.BaseMutationOptions<
    DeleteDataProductMutation,
    DeleteDataProductMutationVariables
>;
export const BatchSetDataProductDocument = gql`
    mutation batchSetDataProduct($input: BatchSetDataProductInput!) {
        batchSetDataProduct(input: $input)
    }
`;
export type BatchSetDataProductMutationFn = Apollo.MutationFunction<
    BatchSetDataProductMutation,
    BatchSetDataProductMutationVariables
>;

/**
 * __useBatchSetDataProductMutation__
 *
 * To run a mutation, you first call `useBatchSetDataProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchSetDataProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchSetDataProductMutation, { data, loading, error }] = useBatchSetDataProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBatchSetDataProductMutation(
    baseOptions?: Apollo.MutationHookOptions<BatchSetDataProductMutation, BatchSetDataProductMutationVariables>,
) {
    return Apollo.useMutation<BatchSetDataProductMutation, BatchSetDataProductMutationVariables>(
        BatchSetDataProductDocument,
        baseOptions,
    );
}
export type BatchSetDataProductMutationHookResult = ReturnType<typeof useBatchSetDataProductMutation>;
export type BatchSetDataProductMutationResult = Apollo.MutationResult<BatchSetDataProductMutation>;
export type BatchSetDataProductMutationOptions = Apollo.BaseMutationOptions<
    BatchSetDataProductMutation,
    BatchSetDataProductMutationVariables
>;
export const UpdateDataProductPropertyDocument = gql`
    mutation updateDataProductProperty($input: UpdateDataProductPropertyInfo!) {
        updateDataProductProperty(input: $input)
    }
`;
export type UpdateDataProductPropertyMutationFn = Apollo.MutationFunction<
    UpdateDataProductPropertyMutation,
    UpdateDataProductPropertyMutationVariables
>;

/**
 * __useUpdateDataProductPropertyMutation__
 *
 * To run a mutation, you first call `useUpdateDataProductPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataProductPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataProductPropertyMutation, { data, loading, error }] = useUpdateDataProductPropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataProductPropertyMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateDataProductPropertyMutation,
        UpdateDataProductPropertyMutationVariables
    >,
) {
    return Apollo.useMutation<UpdateDataProductPropertyMutation, UpdateDataProductPropertyMutationVariables>(
        UpdateDataProductPropertyDocument,
        baseOptions,
    );
}
export type UpdateDataProductPropertyMutationHookResult = ReturnType<typeof useUpdateDataProductPropertyMutation>;
export type UpdateDataProductPropertyMutationResult = Apollo.MutationResult<UpdateDataProductPropertyMutation>;
export type UpdateDataProductPropertyMutationOptions = Apollo.BaseMutationOptions<
    UpdateDataProductPropertyMutation,
    UpdateDataProductPropertyMutationVariables
>;
export const DeleteDataProductPropertyDocument = gql`
    mutation deleteDataProductProperty($input: DeleteDataProductPropertyInfo!) {
        deleteDataProductProperty(input: $input)
    }
`;
export type DeleteDataProductPropertyMutationFn = Apollo.MutationFunction<
    DeleteDataProductPropertyMutation,
    DeleteDataProductPropertyMutationVariables
>;

/**
 * __useDeleteDataProductPropertyMutation__
 *
 * To run a mutation, you first call `useDeleteDataProductPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataProductPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataProductPropertyMutation, { data, loading, error }] = useDeleteDataProductPropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDataProductPropertyMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteDataProductPropertyMutation,
        DeleteDataProductPropertyMutationVariables
    >,
) {
    return Apollo.useMutation<DeleteDataProductPropertyMutation, DeleteDataProductPropertyMutationVariables>(
        DeleteDataProductPropertyDocument,
        baseOptions,
    );
}
export type DeleteDataProductPropertyMutationHookResult = ReturnType<typeof useDeleteDataProductPropertyMutation>;
export type DeleteDataProductPropertyMutationResult = Apollo.MutationResult<DeleteDataProductPropertyMutation>;
export type DeleteDataProductPropertyMutationOptions = Apollo.BaseMutationOptions<
    DeleteDataProductPropertyMutation,
    DeleteDataProductPropertyMutationVariables
>;
export const SyncDataProductOwnersToChannelDocument = gql`
    mutation syncDataProductOwnersToChannel($urn: String!) {
        syncDataProductOwnersToChannel(urn: $urn)
    }
`;
export type SyncDataProductOwnersToChannelMutationFn = Apollo.MutationFunction<
    SyncDataProductOwnersToChannelMutation,
    SyncDataProductOwnersToChannelMutationVariables
>;

/**
 * __useSyncDataProductOwnersToChannelMutation__
 *
 * To run a mutation, you first call `useSyncDataProductOwnersToChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncDataProductOwnersToChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncDataProductOwnersToChannelMutation, { data, loading, error }] = useSyncDataProductOwnersToChannelMutation({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useSyncDataProductOwnersToChannelMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SyncDataProductOwnersToChannelMutation,
        SyncDataProductOwnersToChannelMutationVariables
    >,
) {
    return Apollo.useMutation<SyncDataProductOwnersToChannelMutation, SyncDataProductOwnersToChannelMutationVariables>(
        SyncDataProductOwnersToChannelDocument,
        baseOptions,
    );
}
export type SyncDataProductOwnersToChannelMutationHookResult = ReturnType<
    typeof useSyncDataProductOwnersToChannelMutation
>;
export type SyncDataProductOwnersToChannelMutationResult =
    Apollo.MutationResult<SyncDataProductOwnersToChannelMutation>;
export type SyncDataProductOwnersToChannelMutationOptions = Apollo.BaseMutationOptions<
    SyncDataProductOwnersToChannelMutation,
    SyncDataProductOwnersToChannelMutationVariables
>;
