import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Col, List, Pagination, Row, Empty, message, Checkbox } from 'antd';

import { BulkAction } from '../shared/bulkAction/BulkAction';
import BrowseResultActions from './BrowseResultActions';
import { Notification } from '../shared/Notification';
import analytics, { EventType } from '../analytics';
import BrowseResultCard from './BrowseResultCard';
import ConfirmationModal from '../shared/modals/ConfirmationModal';

import {
    useBatchDeleteDatasetsMutation,
    useGetEntityImportValidationMetadataQuery,
} from '../../graphql/dataset.generated';
import { BrowseResultGroup, EntityType, Entity, EntityImportValidationMetadataInput } from '../../types.generated';
import { useExportEntityMutation } from '../../graphql/mutations.generated';
import { useEntityRegistry } from '../useEntityRegistry';

import utilService from '../../utils/util.service';
import { EntityXlsxExpectedSheetHeader } from '../entity/shared/types';

const EntityList = styled(List)`
    .ant-list-item {
        padding: 24px;
    }
`;

interface Props {
    type: EntityType;
    rootPath: string;
    page: number;
    pageSize: number;
    totalResults: number;
    groups: Array<BrowseResultGroup>;
    entities: Array<Entity>;
    onChangePage: (page: number) => void;
}

const input: EntityImportValidationMetadataInput = {
    entityType: 'dataset',
};

/**
 * Display browse groups + entities.
 */
export const BrowseResults = ({
    type,
    rootPath,
    page,
    pageSize,
    totalResults,
    entities,
    groups,
    onChangePage,
}: Props) => {
    const entityRegistry = useEntityRegistry();
    const history = useHistory();

    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [datasetUrns, setDatasetUrns] = useState<string[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isCsvExporting, setIsCsvExporting] = useState<boolean>(false);
    const [exportEntity, setExportEntity]: any = useState(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [batchDeleteDatasetsMutation] = useBatchDeleteDatasetsMutation();
    const [exportEntityMutation] = useExportEntityMutation();

    useEffect(() => {
        // Reset selected items and "Select All" when the browse path changes
        setSelectedItems([]);
        setIsAllSelected(false);
    }, [rootPath]);

    const { data: headers } = useGetEntityImportValidationMetadataQuery({ variables: { input } });

    const onGroupClick = (group: BrowseResultGroup) => {
        analytics.event({
            type: EventType.BrowseResultClickEvent,
            browsePath: rootPath,
            entityType: type,
            resultType: 'Group',
            groupName: group.originalName,
        });
    };

    const onEntityClick = (entity: Entity) => {
        analytics.event({
            type: EventType.BrowseResultClickEvent,
            browsePath: rootPath,
            entityType: type,
            resultType: 'Entity',
            entityUrn: entity.urn,
        });
    };

    /**
     * Handles the click event on the select all checkbox.
     * Updates the selectedItems state to include all datasets name if the select all checkbox is checked,
     * or clears the selectedItems state if the select all checkbox is unchecked.
     *
     *  * @param event - The click event object.
     */
    const handleSelectAll = (e) => {
        const dsNames: any = [...groups, ...entities];
        const selectedDsNames = e.target.checked ? dsNames.map((item) => item?.name) : [];

        setSelectedItems(selectedDsNames);
        setIsAllSelected(e.target.checked);
    };

    // Handles the deletion of a dataset.
    const handleDelete = async () => {
        setIsLoading(true);
        setIsModalVisible(true);

        try {
            const variables = {
                input: {
                    currentPath: rootPath?.split('/dataset')[1],
                    chosenPaths: selectedItems,
                    datasetUrns: [...datasetUrns],
                },
            };

            // Mutation to delete multiple datasets in a batch operation
            batchDeleteDatasetsMutation({ variables })
                .then(() => {
                    message.success({ content: 'Initiating Dataset Deletion Process', duration: 3 });
                    setSelectedItems([]);
                    setIsAllSelected(false);
                    history.push('/browse/dataset');
                })
                .catch(() => message.error({ content: `Failed to delete the datasets`, duration: 3 }));

            setIsLoading(false);
            setIsModalVisible(false);
        } catch (error) {
            message.error({ content: `Failed to delete the datasets`, duration: 3 });
        }
    };

    // Handles the click event on the checkbox.
    const handleItemCheck = (dsName: string, event: React.MouseEvent) => {
        event.stopPropagation();

        let updatedSelectedDs = [...selectedItems];

        if (updatedSelectedDs.includes(dsName)) {
            updatedSelectedDs = updatedSelectedDs.filter((name) => name !== dsName);
        } else {
            updatedSelectedDs.push(dsName);
        }

        setIsAllSelected(updatedSelectedDs.length === groups.length + entities.length);
        setSelectedItems(updatedSelectedDs);
    };

    // Checks if the type is 'DATASET'.
    const isDataset = () => type === 'DATASET';

    // Handles the deletion of a dataset from the preview page.
    const deleteDatasetFromPreview = (dataset, e) => {
        const dsURN = dataset?.urn;
        let updatedDsUrns = [...datasetUrns];

        if (updatedDsUrns.includes(dsURN)) {
            updatedDsUrns = updatedDsUrns.filter((name) => name !== dsURN);
        } else {
            updatedDsUrns.push(dsURN);
        }

        setDatasetUrns(updatedDsUrns);
        handleItemCheck(dataset.name, e);
    };

    /**
     * Handles the export of the selected datasets to a CSV file.
     * Calls the exportEntityMutation to export the selected datasets to a CSV file.
     * Displays a notification message to the user once the export is completed.
     * Clears the selectedItems state and sets the isAllSelected state to false.
     */
    const exportCsv = () => {
        const dsNames: any = [...groups, ...entities];

        const selectedDs = dsNames
            .filter((item) => selectedItems.includes(item?.name) && !item?.urn)
            .map((item) => item?.name);

        const selectedUrns = dsNames
            .filter((item) => item?.urn && selectedItems.includes(item?.name))
            .map((item) => item?.urn);

        setIsCsvExporting(true);
        setExportEntity(null);

        exportEntityMutation({
            variables: {
                input: {
                    dataset: {
                        currentPath: rootPath?.split('/dataset')[1],
                        chosenPaths: selectedDs,
                        datasetUrns: selectedUrns,
                    },
                    entityType: 'dataset',
                },
            },
        })
            .then((res = {}) => {
                const { data } = res;
                setExportEntity(data?.exportEntity);
                setSelectedItems([]);
                setIsAllSelected(false);
            })
            .finally(() => setIsCsvExporting(false));
    };

    // Displays the confirmation modal dialog when the user clicks the "Delete Datasets" button.
    const confirmDatasetDeletion = () => {
        setIsModalVisible(true);
    };

    // Closes the confirmation modal dialog.
    const onClose = () => {
        setIsModalVisible(false);
    };

    return (
        <div>
            {exportEntity && <Notification exportEntity={exportEntity} />}

            <Row>
                <Col span={24}>
                    <BrowseResultActions
                        selectedItems={selectedItems}
                        isAllSelected={isAllSelected}
                        handleSelectAll={handleSelectAll}
                        confirmDatasetDeletion={confirmDatasetDeletion}
                        exportCsv={exportCsv}
                        isCsvExporting={isCsvExporting}
                        expectedHeaders={
                            (headers?.getEntityImportValidationMetadata?.validationMetadata ||
                                []) as EntityXlsxExpectedSheetHeader[]
                        }
                    />
                </Col>
                {groups.map((group) => (
                    <Col span={24} key={`${group.name}_key`}>
                        <BrowseResultCard
                            onClick={() => onGroupClick(group)}
                            name={group.name}
                            count={group.count}
                            url={`${rootPath}/${utilService.encodeSpecialChars(group.originalName)}`}
                            type={entityRegistry.getCollectionName(type)}
                            selectedItems={selectedItems}
                            handleItemCheck={handleItemCheck}
                        />
                    </Col>
                ))}
                {(!(groups && groups.length > 0) || (entities && entities.length > 0)) && (
                    <EntityList
                        dataSource={entities}
                        split={false}
                        renderItem={(item: any) => (
                            <Row>
                                {isDataset() && (
                                    <Checkbox
                                        aria-label={item?.name}
                                        style={{ paddingLeft: 26 }}
                                        checked={selectedItems.includes(item?.name)}
                                        onClick={(e) => deleteDatasetFromPreview(item, e)}
                                    />
                                )}
                                <List.Item style={{ flex: 1 }} onClick={() => onEntityClick(item as Entity)}>
                                    {entityRegistry.renderBrowse(type, item)}
                                </List.Item>
                            </Row>
                        )}
                        locale={{
                            emptyText: <Empty description="No Entities" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
                        }}
                    />
                )}
                <Col span={24}>
                    <Pagination
                        style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: 16 }}
                        current={page}
                        pageSize={pageSize}
                        total={totalResults}
                        showTitle
                        showLessItems
                        onChange={onChangePage}
                        showSizeChanger={false}
                    />
                </Col>
            </Row>

            <BulkAction
                type="dataset"
                selectedItems={selectedItems}
                exportCsv={exportCsv}
                confirmDatasetDeletion={confirmDatasetDeletion}
                setSelectedItems={setSelectedItems}
            />

            <ConfirmationModal
                title="Delete Dataset"
                okText="Delete"
                cancelText="Cancel"
                isLoading={isLoading}
                onClick={handleDelete}
                visible={isModalVisible}
                onClose={onClose}
                confirmationText="Are you sure you want to delete the datasets? Please note that this action cannot be undone."
            />
        </div>
    );
};
